.about-header {
    background-image: url('../images/about-bg.webp');
    /* min-height: 65vh;     */
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.about-header .about-subheader {
    padding-top: 300px;
    padding-bottom: 200px;
}

.about-header .about-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    /* identical to box height */
    text-align: center;
    color: #BEBEBE;
    padding: 0px 30px;
}

.about-header .about-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    /* or 150% */
    padding-top: 38px;
    text-align: center;

    color: #FFFFFF;
}

.about-header .about-para2 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 2px;
    /* or 150% */

    text-align: center;

    color: #FFFFFF;
}

.about-header .text-slide {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    transform-origin: center;
    padding: 0px 0px;
    /* height: 110px; */
    display: flex;
    align-items: center;
    margin: auto;
    /* object-fit: cover; */
}

.about-header  .text-slide img {
    margin-bottom: 10px;
    max-height: 62px;
}

.about-header .gif-image {
    max-height: 250px;
    text-align: center;
}

.about-header .gif-image img {
    height: 100%;
}

.about-header .headergif {
    width: 80%;
    margin: auto;
}

.about-subheader .aboutmobileviewpara {
    display: none;
}

/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
    .about-subheader .about-heading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
        text-align: center;
        color: #BEBEBE;
        padding: 0px;
        /* margin-bottom: -100px; */
    }

    .about-header .gif-image {
        max-height: 80px;
    }

    .about-subheader .aboutmobileviewpara {
        display: block;
    }

    .about-header .text-slide {
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: content-box;
        transform-origin: center;
        padding: 1px 0px;
        display: flex;
        margin: auto;
    }

    .about-header .capsuleleft img,
    .about-header .capsuleright img {
        height: 15px !important;
        width: auto !important;
  
    }

    .about-header .capsuleleft img {
        margin-right: 10px !important;
    }

    .about-header .capsuleright img {
        margin-left: 15px;
    }

    .about-subheader .aboutmobileview {
        margin-top: -140px;
        margin-bottom: -300px;
        margin-bottom: -300px;
        margin: 0px auto;
        /* padding-top: -90px; */
    }

    .about-subheader .aboutdesktopviewpara {
        display: none;
    }

    .about-header .about-para {
        font-size: 12px;
        line-height: 18px;
        padding-top: 20px;
    }

    .about-subheader {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        margin-bottom: 0px;
    }

    .about-header {
        background-image: url('../images/header-about-mb.webp');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 100px 0;
    }

    .aboutmobileviewpara {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        margin-bottom: -50px;
        padding-top:-20px ;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    /* STYLES GO HERE */

    .about-header .about-para {
        font-size: 18px;
        line-height: normal;
    }
}

/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    .about-header .gif-image {
        max-height: 200px;
    }

    .about-header .about-heading {
        font-size: 76.8px;
        line-height: 94.4px;
    }
    .about-header .about-para {
       
        font-size: 16.8px;
        line-height: 25.6px;
      
    }
}


@media (min-width: 1280px) and (max-width: 1360px) {
    .about-header .gif-image {
        max-height: 210px;
    }

    .about-header .about-heading {
        font-size: 81.6px;
        line-height: 100.3px;
    }
    .about-header .about-para {
       
        font-size: 17.85px;
        line-height: 27.2px;
    }
}

@media only screen and (min-width: 1360px) and (max-width: 1440px) {
    .about-header .gif-image {
        max-height: 230px;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1680px) {
    .about-header .gif-image {
        max-height: 240px;
    }
}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {
    

    .about-header .about-heading {
        font-size: 86.4px;
        line-height: 106.2px;
    }
    .about-header .about-para {
       
        font-size: 18.9px;
        line-height: 28.8px;
    }

}


@media only screen and (min-width: 1680px) and (max-width: 1919px) {
    .about-header .gif-image {
        max-height: 240px;
    }


    .about-header .about-heading {
        font-size: 91.2px;
        line-height: 112.1px;
    }
    .about-header .about-para {
       
        font-size: 19.95px;
        line-height: 30.4px;
    }
}




