header.header-pp {
    width: 100%;
    height: 100%;
    min-height: 65vh;
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
    background: url('../images/headermembeni.webp') no-repeat top left;
    background-size: cover;

}


header.header-pp .hero {
    min-height: 65vh;
    height: 100%;
    position: relative;

}

header.header-pp .hero-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50% , -50%);
}

header.header-pp .hero-text {
    position: relative;
    text-align: center;
}

header.header-pp .hero-text h2 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 74px;
    text-align: center;
    color: #BEBEBE;
}

header.header-pp .hero-text h3 {
    font-family: 'Clash Display';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    text-align: center;
    align-self: center;
    color: #BEBEBE;
}

header.header-pp .hero-text p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: #122051;
}


/* header.header-home .navbar-nav a {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #B2E219;
} */

header.header-pp .text-slide {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    transform-origin: center;
    padding:0px 0px;
    height: 110px;
    /* object-fit: cover; */
}

header.header-pp .text-slide-box {
    overflow: hidden;
}

header.header-pp .text-slider {
    display: flex;
    vertical-align: top;
    justify-content: center;
    min-height: 110px;
    margin-bottom: 50px;
    gap: 20px;
}


header.header-pp .text-slider.gap {
    gap: 20px;
}

header.header-pp .text-slider img {
    height: 100%;
    max-height: 62px;
    width: auto;
    align-self: center;
}




/* For 480 Resolution */  
@media only screen  and (min-device-width : 320px) and (max-device-width : 420px)  { 
    header.header-pp {
        width: 100%;
        height: 100%;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
        background: url('../images/headermembeni-mb.webp') no-repeat top left;
        background-size: cover;
    }
    
    header.header-pp .hero-text h3 {
        font-size: 25px;
        line-height: 28px;
        /* margin-bottom: -400px; */
    }
    /* header.header-pp {
        width: 100%;
        height: 100%;
        min-height: 35vh;} */

    header.header-pp .hero-text .text-slide {
        font-weight: 600;
        font-size: 25px;
        line-height: 28px;
        
    }
    header.header-pp {
        height: 100%;
        min-height: 38vh;   
    }

    header.header-pp .hero {
        min-height: 38vh;
        height: 100%;
        position: relative;
    }

    header.header-pp .hero-content {
        position: absolute;
        top: 85%;
        left: 50%;
    }
    /* header.header-pp .hero {
        min-height: 65vh;
        height: 100%;
        position: relative;
    
    } */
    /* .header-pp .hero-te{
margin-bottom: -180px;
    } */
    .header-pp .pp-mobile-view img{
        width: 7px !important;
        height: 15px !important;
        margin-top: -90px !important;
        padding-top: -10px; 
    }
 .hero-text .member-headmobile-view-para{
      display: none !important;
      
       
    }
    .header-pp .hero-content{
        padding-bottom: -40px !important;
        margin-bottom: -80px !important;
        padding-top: -100px;

    }
    /* header.header-pp .hero{
    min-height: 100px !important;
    } */
    .header-pp .capsuleleft{
        margin-right: -15px !important;
        /* padding-right: 20px !important;  */
    
    }
    .header-pp .capsuleright{
        margin-left: -15px !important;
    }
    /* header.header-pp .hero {
        min-height: 40vh !important;
        height: 60% !important;
        position: relative;
        padding-bottom: -200px;
    
    } */
} 


@media only screen  and (min-device-width : 340px) and (max-device-width : 380px)  { 
    header.header-pp {
        height: 100%;
        min-height: 30vh;   
    }

    header.header-pp .hero {
        min-height: 30vh;
        height: 100%;
        position: relative;
    }
}

@media only screen  and (min-device-width : 380px) and (max-device-width : 440px)  { 
    header.header-pp {
        height: 100%;
        min-height: 25vh;   
    }

    header.header-pp .hero {
        min-height: 25vh;
        height: 100%;
        position: relative;
    }
}


@media only screen  and (min-device-width : 440px) and (max-device-width : 640px)  { 
    header.header-pp {
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
        background: url('../images/headermembeni-mb.webp') no-repeat top left;
    }

    header.header-pp .hero-text h3 {
        font-size: 25px;
        line-height: 28px;
        /* margin-bottom: -400px; */
    }
    /* header.header-pp {
        width: 100%;
        height: 100%;
        min-height: 35vh;} */

    header.header-pp .hero-text .text-slide {
        font-weight: 600;
        font-size: 28px;
        line-height: 28px;
        
    }
    header.header-pp {
        height: 100%;
        min-height: 25vh;
        
    }
    header.header-pp .hero {
        min-height: 25vh;
        /* height: 100%; */
        position: relative;
    
    }
    header.header-pp .hero-content {
        position: absolute;
        top: 95%;
        left: 50%;
    }
    

    .header-pp .pp-mobile-view img{
        width: 7px !important;
        height: 15px !important;
        margin-top: -90px !important;
        padding-top: -10px; 
    }
 .hero-text .member-headmobile-view-para{
      display: none !important;
      
       
    }
    .header-pp .hero-content{
        padding-bottom: -40px !important;
        margin-bottom: -80px !important;
        padding-top: -100px;

    }
    /* header.header-pp .hero{
    min-height: 100px !important;
    } */
    .header-pp .capsuleleft{
        margin-right: -15px !important;
        /* padding-right: 20px !important;  */
    
    }
    .header-pp .capsuleright{
        margin-left: -15px !important;
    }
    /* header.header-pp .hero {
        min-height: 40vh !important;
        height: 60% !important;
        position: relative;
        padding-bottom: -200px;
    
    } */
} 




/* For 640 Resolution */  
@media only screen and (min-device-width : 481px) and (max-device-width : 767px)  {
    
    header.header-pp .text-slider {
        align-items: center;
    }

    header.header-pp .hero-text h2 {
        font-size: 28px;
        line-height: 28px;
    }

    header.header-pp .hero-text h3 {
        font-size: 30px;
        line-height: 37px;
        height: auto;
    }

    header.header-pp .text-slider img {
        max-height: 40px;
    }

}  


/* For 1024 Resolution */  
@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1024px)  
{ /* STYLES GO HERE */
    header.header-pp .text-slider {
        align-items: center;
    }

    header.header-pp .hero-text h2 {
        font-size: 28px;
        line-height: 28px;
    }

    header.header-pp .hero-text h3 {
        font-size: 30px;
        line-height: 37px;
        height: auto;
    }

    header.header-pp .text-slider img {
        max-height: 40px;
    }
} 


/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    header.header-pp .hero-content {
        top: 70%;
    }
    header.header-pp  .hero-text h3 {
        font-size: 76.8px;
        line-height: 94.4px;
        line-height: 118px;
    }
    
}


@media (min-width: 1280px) and (max-width: 1360px) {

    header.header-pp  .hero-text h3 {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }
}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {
    
    header.header-pp .hero-content {
        top: 70%;
    }
    header.header-pp  .hero-text h3 {
        font-size: 86.4px;
        line-height: 106.2px;
        line-height: 118px;
    }

}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {
    header.header-pp .hero-content {
        top: 70%;
    }
    header.header-pp  .hero-text h3 {
        font-size: 91.2px;
        line-height: 112.1px;
        line-height: 118px;
    }
}