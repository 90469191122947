.homeblog-section {
    padding: 5% 0;
  }
  
  .homeblog-section h3.heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    letter-spacing: 0.04em;
    color: #122051;
  }

  .homeblog-section .single-blog h2 {
    font-size: 28px;
    font-family: Clash Display;
    font-weight: 600;
    letter-spacing: 0.84px;
  }

  .homeblog-section .related-blog {
    justify-content: space-between;
    max-width: 1200px;
  }
  .homeblog-section .homepage .single-blog .blog-content img {
    width: 100%;
    max-height: 350px !important;
    max-height: unset !important;
  }

  .homeblog-section  .single-blog {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 47%;
    flex-wrap: wrap;
    padding: 35px 15px 15px 35px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}


/* .related-blog .single-blog .blog-content img {
    width: 100%;
    max-height: 280px;
} */

  /* For 480 Resolution */
  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  
    .homeblog-section h3.heading {

      font-size: 24px;
      line-height: 30px;
    }
  
    .homeblog-section  .related-blog {
      display: flex;
      gap:0px;
    }

    .homeblog-section .related-blog .single-blog {
      width: 95%;
      margin:20px auto;
      padding: 25px 15px 15px 25px;
    }

    .homeblog-section .related-blog .single-blog h2 {
      font-size: 16px;
    }

    .homeblog-section .single-blog p {
        display: none;
    }
    .homeblog-section .single-blog .overlay-box img,.homeblog-section .single-blog .overlay-box svg {
      width: 30px;
    }
    .homeblog-section .related-blog .single-blog .green-bg {
      left: 0px;
      top: -5px;
     }

  }
  

  @media (min-width: 768px) and (max-width: 1023px) {
    .homeblog-section h3.heading {
      font-size: 35px;
      line-height: 30px;
    }

    .homeblog-section .related-blog {
      display: flex;
      gap: 50px;
    }
    .related-blog .single-blog {
        width: 100% !important;
        max-width: 650px;
        margin: auto;
    }

    .homeblog-section .single-blog h2 {
      font-size: 22px;
      line-height: normal;
    }
  }
  
  
/* For 1024 Resolution */


@media (min-width: 1024px) and (max-width: 1280px) {
  
  .homeblog-section h3.heading {
    font-size: 38.4px;
    line-height: 47.2px;   
  }  

  .homeblog-section .single-blog h2 {
    font-size: 16px;
  }

  .homeblog-section .single-blog .blog-content img {
    width: 100%;
    max-height: 224px;
  }

  .related-blog .single-blog {
    padding: 35px 15px 15px 35px;
  }
}


@media only screen and (min-width: 1280px) and (max-width: 1360px) {
  .homeblog-section h3.heading {
    font-size: 40.8px;
    line-height: 50.15px;   
  }  

  .homeblog-section .single-blog h2 {
    font-size: 20px;
  }
  
  .homeblog-section .single-blog .blog-content img {
    width: 100%;
    max-height: 238px;
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1600px) {
  .homeblog-section h3.heading {
    font-size: 43.2px;
    line-height: 53.1px;   
  }  

  .homeblog-section .single-blog h2 {
    font-size: 25.2px;
    font-size: 23.5px;
  }
  .homeblog-section .single-blog .blog-content img {
    width: 100%;
    max-height: 252px !important;
  }

}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .homeblog-section h3.heading {
    font-size: 45.6px;
    line-height: 56.05px;   
  }  

  .homeblog-section .single-blog h2 {
    font-size: 26.6px;
  }
  
  .homeblog-section .single-blog.homepage .blog-content img {
    width: 100%;
    /* width: auto !important; */
    max-height: 296px !important;
    max-height: unset !important;
  }

}

@media only screen and (min-width: 1920px) {
  .homeblog-section h3.heading {
    font-size: 52px;
    line-height: 62px;
   
  }  
  .exclusiveclub p.numheading, .exclusiveclub p.exclusive-para {
   
    font-size: 24px;
    line-height: 29px;
    
  }
}
