
.postform-section {
    margin-top: -7rem !important;
}


.postform-section  .col-md-6.col-lg-5 {
    max-width: 580px;
}

.postform-section input,
.postform-section select {
    position: relative;
    background: #D9D9D9;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    height: 60px;
    border-radius: 15px;
    color: rgba(18, 32, 81, 0.3);
    /* color: rgb(33 37 41 / 60%); */
    padding-left: 30px;
}

.postform-section .country-box {
    position: relative;
}

/* .postform-section select {
    position: relative;
} */

.postform-section .country-box::after {
    position: absolute;
    right: 50px;
    top: 50%;
    content: "";
    z-index: 2;
    width: 0px;
    height: 0;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 18px solid #BEBEBE;
    transform: translateY(-50%) rotate(90deg);
}

.postform-section .country-box.select::after {
    transform: translateY(-50%) rotate(-90deg);
}


/* .postform-section select::after {
    position: absolute;
    right: 50px;
    top: 50%;
    content: "";
    z-index: 2;
    width: 0px;
    height: 0;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    border-left: 18px solid #BEBEBE;
    transform: translateY(-50%) rotate(90deg);
} */

.postform-section textarea {

    background: #D9D9D9;
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    border-radius: 15px;
    color: rgba(18, 32, 81, 0.3);
    /* color: rgb(33 37 41 / 60%); */
    padding-left: 30px;
    padding-top: 20px;
}

textarea::placeholder {
    padding-top: 0px;
}

.postform-section input:focus,
.postform-section textarea:focus,
.postform-section select:focus {
    border-color: #D9D9D9;
    box-shadow: none;
}


.postform-section .applynow-btn {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #122051;
    transition: all 0.5s;
}
.postform-section .applynow-btn:hover {
    background: #122051;    
    color:#B2E219;
}
.postform-section p.form-success {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #122051;
}

input:focus  input::Placeholder {
    display: none;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 420px) {

    /* .mobile{
        display: block;
    }
    .desktop{
        display: none;
    } */
    .form-control {
        height: 55px !important;
        border-radius: 8px !important;
    }
    .postform-section .form-control::placeholder{
       
        transform: translateY(-3px);
    }
    .postform-section input, .postform-section select{
        padding-left: 15px;
        font-size: 12px;
        line-height: 15px;
    }
    .postform-section {
        padding-bottom: 120px;
    }
    
    /* .form-group {
        padding-top: 10px !important;
    } */


    input::Placeholder {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        /* padding-top: -20px !important;
        margin-top: -20px !important;  */
        /* display: flex;
        align-items:center;
        margin-top: 29px;
        padding-top: 89px; */

    }

    /* .inputpadding::placeholder{
        padding-top: 0px !important;
margin: 0px !important;
    }  */
    .heighttextarea {
        height: 130px !important;
    }

    .postform-section select {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 15px !important;
    }

    .postform-section textarea::placeholder {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        padding-top: 10px !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        
        /* margin-bottom: 20px !important; */
    }
    .postform-section textarea{
        padding-left: 15px;
    }
    .postform-section .country-box::after {
        display: none;
    }

    /* textarea
{
    height: 30px;
} */
    form {
        /* padding-left: 10px; */
        margin: auto;
        padding: 0px 28px;
    }

    .postform-section {
        margin-top: -35px;
    }

    .postform-section .applynow-btn {
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        text-align: center !important;
        letter-spacing: 0.02em !important;
        /* padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-right: 60px !important;
        padding-left: 60px !important; */
        padding: 15px 40px 15px 40px !important;
        /* font-weight: 600; */
        /* font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.02em; */
    }
}

.postform-section .errormsg {
    color: red;
    font-weight: 600;

}



@media only screen and (min-device-width : 420px) and (max-device-width : 767px) {

    /* .mobile{
        display: block;
    }
    .desktop{
        display: none;
    } */


    .form-control {
        height: 55px !important;
        border-radius: 8px !important;
    }

    .heighttextarea {
        height: 130px !important;
    }
    .postform-section .form-control::placeholder{
       
        transform: translateY(-3px);
    }
    .postform-section input, .postform-section select{
        padding-left: 15px;
    }
    .form-group {
        padding-top: 10px !important;
    }

    input::Placeholder {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }

    .postform-section select {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 15px !important;
    }

    .postform-section textarea::placeholder {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        padding-top: 10px !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        /* margin-bottom: 20px !important; */
    }
    .postform-section textarea{
        padding-left: 15px;
    }
    .postform-section .country-box::after {
        display: none;
    }

    /* textarea
{
    height: 30px;
} */
    form {
        /* padding-left: 10px; */
        margin: auto;
        padding: 0px 28px;
    }

    .postform-section {
        margin-top: -35px;
    }

    .postform-section .applynow-btn {
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        text-align: center !important;
        letter-spacing: 0.02em !important;
        /* padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-right: 60px !important;
        padding-left: 60px !important; */
        padding: 20px 60px 20px 60px !important;
        /* font-weight: 600; */
        /* font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.02em; */
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

    /* .mobile{
        display: block;
    }
    .desktop{
        display: none;
    } */


    .form-control {
        height: 55px !important;
        border-radius: 8px !important;
    }

    .heighttextarea {
        height: 130px !important;
    }
    .postform-section .form-control::placeholder{
       
        transform: translateY(-3px);
    }
    .postform-section input, .postform-section select{
        padding-left: 15px;
    }
    .form-group {
        padding-top: 10px !important;
    }

    input::Placeholder {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }

    .postform-section select {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 15px !important;
    }

    .postform-section textarea::placeholder {
        /* font-size:large !important; */
        color: rgba(18, 32, 81, 0.3) !important;
        padding-top: 10px !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        /* margin-bottom: 20px !important; */
    }
    .postform-section textarea{
        padding-left: 15px;
    }
    .postform-section .country-box::after {
        display: none;
    }

    /* textarea
{
    height: 30px;
} */
    form {
        /* padding-left: 10px; */
        margin: auto;
        padding: 0px 28px;
        max-width: 650px;
    }

    .postform-section {
        margin-top: -35px;
    }

    /* .postform-section .col-md-6 {
        max-width: 650px;
        width: 100%;
    } */

    .postform-section .applynow-btn {
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 20px !important;
        text-align: center !important;
        letter-spacing: 0.02em !important;
        /* padding-top: 20px !important;
        padding-bottom: 20px !important;
        padding-right: 60px !important;
        padding-left: 60px !important; */
        padding: 20px 60px 20px 60px !important;
        /* font-weight: 600; */
        /* font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.02em; */
    }

}

@media (min-device-width: 1024px) and (max-device-width: 1279px) {

    .postform-section textarea::placeholder {
        font-size: 18px;
        line-height: 23px;
    }

    input::Placeholder {
        font-size: 18px;
        line-height: 23px;
    }

    .postform-section select {
        font-size: 18px;
        line-height: 23px;
    }

    .postform-section .applynow-btn {
        font-size: 24px;
        line-height: 27.2px;
        padding:22px 40px;
    }
}



@media only screen and (min-width: 1360px) and (max-width: 1680px) {
    .postform-section .applynow-btn {
        font-size: 25.2px;
        line-height: 30.6px;
        padding:28px 60px;
        padding:20px 50px;
    }
}


@media only screen and (min-width: 1680px) and (max-width: 1919px) {

    .postform-section .applynow-btn {
        font-size: 26.6px;
        line-height: 32.3px;
        padding:30px 63.65px;
        padding:20px 50px;
    }
}
