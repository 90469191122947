.pubclub-benefit {
    background-image: url('../images/signleheader-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-size: cover;
    height: auto;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
}

.pubclub-benefit .header-container {
    max-width: 980px;
    width: 75%;
    height: 100%;
    text-align: center;
    margin: auto;
    /* padding: 20px 0px 50px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.pubclub-benefit .d-flex.header-img {
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.thumb-img img {
    width: 100%;
    max-height: 400px;
}

.pubclub-benefit .benefit-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #FFFFFF;
}

.pubclub-benefit .pubclub-image {
  margin-top: 240px;
 margin-bottom: 50px;
   width: 95%;
   height: 330px;
}
.pubclub-benefit .pubclub-benefit-para {
    font-family: 'Gotham';
font-style: normal;
font-weight: 700;
font-size: 21px;
line-height: 26px;
text-align: center;
color: #BEBEBE;
}


.pubclub-benefit .header-img .left-img img, 
.pubclub-benefit .header-img .right-img img {
    height: 62px;
}

.pubclub-benefit .sideimg,.pubclub-benefit .sideimg2{
margin-top: 360px;
}
.pubclub-benefit .sideimg2{
    padding-left: 50px;
}
.pubclub-benefit .sideimg{
    padding-left: 20px;
}



/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    .pubclub-benefit {
        width: 100%;
        height: auto;
        padding-top:80px;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/signleheader-mb-bg.webp') no-repeat top left;
        background-size: cover;
    }

    .pubclub-benefit .d-flex.header-img {
        gap: 10px;
    }
    
    .pubclub-benefit .header-img .left-img img, .pubclub-benefit .header-img .right-img img {
        max-height: 24px;
    }

    .pubclub-benefit .benefit-heading {
        font-size: 18px;
        line-height: 20px;
    }
    
    .pubclub-benefit .pubclub-image{
       margin-top: 240px;
       margin-bottom: 50px;
       width: 95%;
       height: 330px;
    }
    .pubclub-benefit .pubclub-benefit-para{
        font-size: 10px;
        line-height: 12px;

    }
    



}



/* For 640 Resolution */
@media only screen and (min-device-width : 480px) and (max-device-width : 768px) {
    .pubclub-benefit {
        width: 100%;
        height: auto;
        padding-top:80px;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/signleheader-mb-bg.webp') no-repeat top left;
        background-size: cover;
    }

    .pubclub-benefit .d-flex.header-img {
        gap: 15px;
    }
    
    .pubclub-benefit .header-img .left-img img, .pubclub-benefit .header-img .right-img img {
        max-height: 32px;
    }

    .pubclub-benefit .benefit-heading {
        font-size: 18px;
        line-height: 20px;
    }
    
    .pubclub-benefit .pubclub-benefit-para{
        font-size: 10px;
        line-height: 12px;

    }
    
}



@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* STYLES GO HERE */
    .pubclub-benefit .benefit-heading {
        font-size: 30px;
        line-height: normal;
    }
}


/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {

    .thumb-img img {
        width: 100%;
        max-height: 300px;
    }

    .benefit-section .benefit-section-para figure img {
        width: 80% !important;
        height: auto !important;
    }
    
    .pubclub-benefit .benefit-heading {
        font-size: 38.4px;
        line-height: 47.2px;
        font-size: 30.4px;
        line-height: 35.2px;
    }

    .pubclub-benefit .pubclub-benefit-para {
        font-size: 16.8px;
        line-height: 20.8px;
    }
    
}


@media (min-width: 1281px) and (max-width: 1360px) {

    .thumb-img img {
        width: 100%;
        max-height: 320px;
    }

    .benefit-section .benefit-section-para figure img {
        width: 80% !important;
        height: auto !important;
    }

    .pubclub-benefit .benefit-heading {
        font-size: 37.8px;
        line-height: 40.15px;
    }

    .pubclub-benefit .pubclub-benefit-para{
        font-size: 17.85px;
        line-height: 22.1px;
    }
}

@media only screen and (min-width: 1361px) and (max-width: 1440px) {

    .thumb-img img {
        width: 100%;
        max-height: 350px;
    }
    
    .benefit-section .benefit-section-para figure img {
        width: 80% !important;
        height: auto !important;
    }

    .pubclub-benefit .benefit-heading {
        font-size: 38.2px;
        line-height: 42.1px;
    }

    .pubclub-benefit .pubclub-benefit-para{
        font-size: 18.9px;
        line-height: 23.4px;
    }

}

@media only screen and (min-width: 1441px) and (max-width: 1680px) {
    
    .pubclub-benefit .benefit-heading {
        font-size: 43.2px;
        line-height: 53.1px;
    }

    .pubclub-benefit .pubclub-benefit-para{
        font-size: 18.9px;
        line-height: 23.4px;
    }

}


@media only screen and (min-width: 1601px) and (max-width: 1919px) {
    .pubclub-benefit .benefit-heading {
        font-size: 45.6px;
        line-height: 56.05px;
    }

    .pubclub-benefit .pubclub-benefit-para{
        font-size: 19.95px;
        line-height: 24.7px;
    }

}

