header.header-member {
    width: 100%;
    height: 100%;
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
    background: url('../images/headermember.webp') no-repeat top left;
    background-size: contain;
    background:unset !important;
}


header.header-member .bg,
header.header-member .bg img {
    width: 100%;
    max-width: 100vw;
    height:auto;
}


header.header-member .hero {
    position: relative;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
}

header.header-member .hero-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

header.header-member .gif-image {
    text-align: center;
}

header.header-member .gif-image img {
    height: 100%;
    width: auto;
    max-height: 100px;
}



header.header-member .hero-text {
    position: relative;
    text-align: center;
}

header.header-member .hero-text .headermembergif {
    width: 50%;
    margin: auto;
}

header.header-member .hero-text h2 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 74px;
    text-align: center;
    color: #BEBEBE;
}

header.header-member .hero-text h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px !important;
    text-align: center;
    align-self: center;
    color: #122051;
}

header.header-member .hero-text p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    color: #122051;
}


/* header.header-home .navbar-nav a {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #B2E219;
} */

header.header-member .text-slide {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    transform-origin: center;
    padding: 0px 0px;
    height: 110px;
    /* object-fit: cover; */
}

header.header-member .text-slide-box {
    overflow: hidden;
}

header.header-member .text-slider {
    display: flex;
    vertical-align: top;
    justify-content: center;
    min-height: 110px;
    margin-bottom: 10px;
    gap: 20px;
}


header.header-member .text-slider.gap {
    gap: 20px;
}

header.header-member .text-slider img {
    height: 62px;
    width: auto;
    align-self: center;
}


.memmobview-para{
    display: none;  
}



/* For 420 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 420px) {
    header.header-member .gif-image img {
        max-height: 30px;
    }

    .memdesktopview-para{
        display: none;
    }
    .memmobview-para{
        margin-top: -20px;
        display: block;
    }

    header.header-member .text-slide-box {
        overflow: unset;
    }
    
    header.header-member .hero-text h2 {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    header.header-member .hero-text h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 28px;
        position: relative;
        text-align: center;
        color: #122051;
    }
    /* header.header-member .hero-text .text-slider .capsuleimg{
        position: relative;
        top: 100% !important;
    } */
     header.header-member .text-slider{
        position: relative;
        top: 80%;
        z-index: 10 !important;
        max-height: 40px;
        min-height: unset;
        gap:10px;
    } 
    header.header-member .text-slider img{
        height: 18px;
        width: 10px !important;
    }

    header.header-member .hero-text p {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 9px;
        line-height: 14px;
        margin-top: 0px;
        text-align: center;
        color: #122051;
    }

    header.header-member {
        background-image: url('../images/membermobileimg.webp') ;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        width: 100%;
        height: 100%;
        min-height: 30vh;
        background: unset !important;
        position: relative;
        /* width: 100%;
        height: 50% !important; */
    }

    /* header.header-member {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
        background: url('../images/headermember.webp') no-repeat top left;
        background-size: cover;
    } */


    header.header-member .hero {
        top: 70%;
    }

    header.header-member .hero-content {
        position: absolute;
        top: 60%;
    }

    .memdesktopview-para{
        display: none;
    }
    header.header-member .hero-text h2 {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    header.header-member .hero-text h3 {
        font-size: 30px !important;
        line-height: 37px !important;
    }

}
@media only screen and (min-device-width : 320px) and (max-device-width : 330px) {
    header.header-member .hero-content {
        transform: translate(-50%, -70%);
    }    
}

@media only screen and (min-device-width : 420px) and (max-device-width : 767px) {
   
    header.header-member .gif-image img {
        max-height: 40px;
    }

    .memmobview-para{
            display: block;
        }
    
    .memdesktopview-para{
        display: none;
        
    }
    .memmobview-para{
        margin-top: -20px;
        
    }
    
    header.header-member .hero-text h2 {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    header.header-member .hero-text h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 37px;
        position: relative;
        text-align: center;
        height: auto;
        color: #122051;
        margin-bottom: 0px;
    }
    /* header.header-member .hero-text .text-slider .capsuleimg{
        position: relative;
        top: 100% !important;
    } */
     header.header-member .text-slider{
        position: relative;
        z-index: 10 !important;
        min-height: unset;
        gap:10px;
    } 
    header.header-member .text-slider img{
        height: 18px;
        width: 10px !important;
    }

    header.header-member .hero-text p {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        margin-top: 0px;
        text-align: center;

        color: #122051;
    }

    header.header-member {
        background-image: url('../images/membermobileimg.webp') !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        width: 100%;
        height: 100%;
        min-height: 30vh;
        background-image: none !important;
        background: unset;
        position: relative;
        /* width: 100%;
        height: 50% !important; */
    }

    /* header.header-member {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
        background: url('../images/headermember.webp') no-repeat top left;
        background-size: cover;
    } */


    header.header-member .hero {
        top: 70%;
    }

    header.header-member .hero-content {
        position: absolute;
        top: 60%;
    }

    .memdesktopview-para{
        display: none;
    }
    header.header-member .hero-text h2 {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    header.header-member .hero-text h3 {
        font-size: 30px !important;
        line-height: 37px !important;
    }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
   
    header.header-member .gif-image img {
        max-height: 40px;
    }

    .memmobview-para{
            display: block;
        }
    
    .memdesktopview-para{
        display: none;
        
    }
    .memmobview-para{
        margin-top: -20px;
        
    }
    
    header.header-member .hero-text h2 {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    header.header-member .hero-text h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 37px;
        position: relative;
        text-align: center;
        height: auto;
        color: #122051;
        margin-bottom: 0px;
    }
    /* header.header-member .hero-text .text-slider .capsuleimg{
        position: relative;
        top: 100% !important;
    } */
     header.header-member .text-slider{
        position: relative;
        z-index: 10 !important;
        min-height: unset;
        gap:10px;
    } 
    header.header-member .text-slider img{
        height: 18px;
        width: 10px !important;
    }

    header.header-member .hero-text p {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
        margin-top: 0px;
        text-align: center;

        color: #122051;
    }

    header.header-member {
        background-image: url('../images/membermobileimg.webp') !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        width: 100%;
        height: 100%;
        min-height: 30vh;
        background-image: none !important;
        background: unset;
        position: relative;
        /* width: 100%;
        height: 50% !important; */
    }

    /* header.header-member {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
        background: url('../images/headermember.webp') no-repeat top left;
        background-size: cover;
    } */


    header.header-member .hero {
        top: 70%;
    }

    header.header-member .hero-content {
        position: absolute;
        top: 60%;
    }

    .memdesktopview-para{
        display: none;
    }
    header.header-member .hero-text h2 {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    header.header-member .hero-text h3 {
        font-size: 30px !important;
        line-height: 37px !important;
    }

}


@media (min-width: 1024px) and (max-width: 1080px) {

    header.header-member .hero {
        top: 45% !important;
    }
}

/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {

    header.header-member .hero {
        top: 50%;
    }

    header.header-member .gif-image img {
        max-height: 80px;
    }

    header.header-member .hero-text h3 {
        font-size: 76.8px;
    }

    header.header-member .hero-text p {
        font-size: 16.8px;
        line-height: 25.6px;
      
    }
}


@media (min-width: 1280px) and (max-width: 1360px) {
    header.header-member .hero-content { 
        top: 62%;
        }

    header.header-member .gif-image img {
        max-height: 85px;
    }
    

    header.header-member .hero {
        top: 50%;
    }

    header.header-member .hero-text h3 {
        font-size: 81.6px;
    }

    header.header-member .hero-text p {
        font-size: 17.85px;
        line-height: 27.2px;
    }
}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {
    
    header.header-member .hero {
        top: 55%;
    }

    header.header-member .gif-image img {
        max-height: 90px;
    }

    header.header-member .hero-text h3 {
        font-size: 86.4px;
    }

    header.header-member .hero-text p {
        font-size: 18.9px;
        line-height: 28.8px;
    }

}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {

    header.header-member .hero {
        top: 60%;
    }

    header.header-member .gif-image img {
        max-height: 95px;
    }

    header.header-member .hero-text h3 {
        font-size: 91.2px;
        /* line-height: 112.1px; */
    }

    header.header-member .hero-text p {
        font-size: 19.95px;
        line-height: 30.4px;
    }
}


