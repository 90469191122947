 .benefit-section .benefit-section-para {
     font-family: 'Gotham';
     font-style: normal;
     font-weight: 500;
     font-size: 20px;
     line-height: 29px;
     color: #122051;
     padding: 0px 50px 30px 50px;
     margin-left: 60px;
     margin-right: 20px;
     margin: auto auto;
 }


.benefit-section .benefit-section-para figure {
  text-align: center;
  max-width: 80%;
  margin: 30px auto;
}


.benefit-section .benefit-section-para figure img {
  width: 100% !important;
  height: auto !important;
}

 /* benefit-blog-section  */
 .benefit-blog-section {
     background: rgba(217, 217, 217, 0.5);
 }

 .benefit-blog-section .blogheading {
     font-family: 'Clash Display';
     font-family: 'Gotham';
     font-style: normal;
     font-weight: 600;
     font-size: 48px;
     line-height: 59px;

     color: #122051;

 }

 .benefit-blog-section .viewbtn {
     font-family: 'Gotham';
     font-style: normal;
     font-weight: 700;
     font-size: 21px;
     line-height: 26px;
     text-decoration-line: underline;
     text-decoration-color:#D9D9D980; ;
     
     float:right;
     margin-top: 10px;
     color: #122051;
 }

 .benefit-blog-section .blog-section-div{
    padding-top: 60px;
    padding-bottom: 60px;

 }

 .related-blog {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    gap: 50px;
    flex-wrap: wrap;
}

.related-blog .single-blog {
    background: #ffffff;
}



.related-blog  .single-blog {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 47%;
    flex-wrap: wrap;
    padding: 45px 25px 25px 45px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}


.related-blog .single-blog .blog-content img {
    max-height: 280px;
    width: 100%;
}


/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  .benefit-blog-section .related-blog {
    display: flex;
    justify-content: flex-start;
    max-width: 200% !important;
    width: 190% !important;
    gap: 15px;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .benefit-section {
    padding-top: 0rem !important;
  }

  .benefit-section .benefit-section-para {
    font-size: 12px;
    line-height: 13.2px;
    padding: 0px;
    width: 80%;
    margin: auto;
  }

  .benefit-blog-section .blog-section-div {
    align-items: center;
  }

  .benefit-blog-section .blogheading {
      font-size: 16px;
      line-height: 20px;
  }

  .benefit-blog-section .viewbtn {
    font-size: 16px;
    line-height: 20px;
  }


  .blog-list {
    gap: 20px;
  }
  .related-blog .single-blog {
    width: 95%;
    margin:20px auto;
  }


  /* blogs */

  .benefit-blog-section .related-blog  .single-blog {
    cursor: pointer;
    padding: 25px 15px 15px 25px;
    width: 48%;
  }

  .benefit-blog-section .blog-section-div {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .overflow-x-mb {
    overflow-x: auto;
  }

  .related-blog  .single-blog h2 {
    font-size: 12px;
  }

  .related-blog  .single-blog p {
    display: none;
  }

.related-blog .single-blog .blog-content img {
  max-height: 280px;
  width: 100%;
}

.benefit-blog-section .related-blog  .single-blog .overlay-box img,.benefit-blog-section .related-blog  .single-blog .overlay-box svg {
  width: 30px;
}

.benefit-blog-section .related-blog  .single-blog .green-bg {
  left: 0px;
  top: -5px;
 }

}


/* For 480 Resolution */
/* @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .benefit-section {
    padding-top: 0rem !important;
  }


  .benefit-section .benefit-section-para {
    font-size: 12px;
    line-height: 13.2px;
    padding: 0px;
    width: 80%;
    margin: auto;
  }

  .benefit-blog-section .blog-section-div {
    align-items: center;
  }

  .benefit-blog-section .blogheading {
      font-size: 16px;
      line-height: 20px;
  }


  .blog-list {
    gap: 20px;
  }
  .related-blog .single-blog {
    width: 95%;
    margin:20px auto;
  }


} */



/* For 640 Resolution */
@media only screen and (min-device-width : 480px) and (max-device-width : 767px) {

  .benefit-section .benefit-section-para {
    font-size: 12px;
    line-height: 13.2px;
    line-height: normal;
    padding: 0px;
    width: 90%;
    margin: auto;
  }

  .blog-content h2 {
    font-size: 16px;
  }
  .blog-content p {
    display: none;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
  .benefit-section .benefit-section-para {
    font-size: 16px;
    line-height: normal;
  }

  .benefit-blog-section .blogheading,
  .benefit-blog-section .viewbtn {
    font-size: 21px;
  }

}







/* For 1024 Resolution */
  @media (min-width: 1024px) and (max-width: 1280px) {
    
    .benefit-blog-section .blogheading {
      font-size: 38.4px;
      line-height: 47.2px;   
      font-size: 16.8px;
      line-height: 20.8px; 
      
    }  

    .benefit-blog-section .viewbtn {
        font-size: 16.8px;
        line-height: 20.8px;  
    }
  
    .blog-content h2 {
        font-size: 16.8px;
    }

    .tablinks h3.tab-heading {
      font-size: 16.8px;
      line-height: 20.8px;
    }

    .blog-content p {
        font-size: 12.8px;
        line-height: 15.2px;
    }
  }
  
  
  @media only screen and (min-width: 1280px) and (max-width: 1360px) {

    .benefit-blog-section .blogheading {
        font-size: 40.8px;
        line-height: 50.15px;   
        font-size: 17.85px;
        line-height: 22.1px;
    }  
  
    .benefit-blog-section .viewbtn {
     
      font-size: 17.85px;
      line-height: 22.1px;
    }

    .blog-content h2 {
        font-size: 17.85px;
    }
    .blog-content p {
        font-size: 13.6px;
        line-height: 15.12px;
    }

}
  
  @media only screen and (min-width: 1360px) and (max-width: 1600px) {

    .benefit-blog-section .blogheading {
        font-size: 43.2px;
        line-height: 53.1px;  
        font-size: 18.9px;
        line-height: 23.4px; 
    }  
  
    .benefit-blog-section .viewbtn {
      font-size: 18.9px;
      line-height: 23.4px;
    }
    .blog-content h2 {
        font-size: 18.9px;
    }

    .blog-content p {
        font-size: 14.4px;
        line-height: 17.1px;
    }
  }
  
  
  @media only screen and (min-width: 1600px) and (max-width: 1919px) {
    .benefit-blog-section .blogheading {
        font-size: 45.6px;
        line-height: 56.05px;   
        font-size: 19.95px;
        line-height: 24.7px;
    }  
  
    .benefit-blog-section .viewbtn {
      font-size: 19.95px;
      line-height: 24.7px;
    }
    .blog-content h2 {
        font-size: 19.95px;
    }
    .blog-content p {
        font-size: 15.2px;
        line-height: 18.05px;
    }
  }
  

  @media only screen and (min-width: 1920px) {
    .benefit-blog-section .blogheading {
        font-size: 52px;
        line-height: 62px;
        font-size: 24px;
        line-height: 29px; 
    }  

    .benefit-blog-section .viewbtn {
      font-size: 24px;
      line-height: 29px;      
    }
    .blog-content h2 {
        font-size: 24px;
    }
    .blog-content p {
        font-size: 18px;
        line-height: 21px;
    }
  }
  
  