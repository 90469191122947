.advisory-council1 {
    background-image: url(../images/advisorycouncil.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-image: none !important;
    /* min-height: 100%; */
    /* height: 100% !important; */
    width: 100%;
    position: relative;
}

.advisory-council1 .bg,
.advisory-council1 .bg img {
    width: 100%;
    max-width: 100vw;
    height:auto;
}



.advisory-council1 .advisory-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    text-align: center;
    color: #122051;
}

.advisory-council1 .council-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;
    /* or 140% */

    text-align: center;

    color: #122051;

}

/* padding to advisory */
.advisory-council1 .advisory {
    /* padding-top: 550px;
    padding-bottom: 300px;
    padding-top: 0px !important; */
    position: absolute;
    top:60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.advisory-council1 .second-para {
    margin-top: -10px;
}

.advisorymobile-view-para {
    display: none;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 380px) {
    .advisory-council1 .advisory {
        top: 70% !important;
    }
    .advisory-council1 .council-para {
        font-weight: 700 !important;
        font-size: 9px !important;
        line-height: 13px !important;
    }

}

/* For 420 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .advisorymobile-view-para {
        display: block;
    }

    .advisory-council1 .advisory-heading {
        font-weight: 600;
        font-size: 25px;
        line-height: 30px;
    }

    /* .advisory-council1 {
        background-image: url(../images/advisorycouncilbgmobile.webp) ;
        background-repeat: no-repeat;
        background-size: contain;
    } */


    /* .advisory-council1 .mobile-view{
        display: flex;
        justify-content: center;
        align-items: center;
    } */
    /* padding to advisory */

    .advisory-council1 .advisory {
        /* position: relative; */
        /* padding-top: 135px !important; */
        /* padding-bottom: 100px !important; */
        top: 70%;
    }

    .advisorydesktop-view-para {
        display: none;
    }

    .advisory-council1 .council-para {
        font-weight: 700;
        font-size: 10px;
        line-height: 14px;
    }

    /* .advisory-council1{
        padding-top: 100px;
    } */
}

/* For 480 Resolution */
@media only screen and (min-device-width : 480px) and (max-device-width : 767px) {
    .advisory-council1 .advisory {
        top: 65% !important;
    }

    .advisorymobile-view-para {
        display: block;
    }

    .advisory-council1 .advisory-heading {
        font-weight: 600;
        font-size: 28px;
        line-height: 28px;
    }

    /* .advisory-council1 {
        background-image: url(../images/advisorycouncilbgmobile.webp);
        background-repeat: no-repeat;
        background-size: contain;
    } */

    /* .advisory-council1 .mobile-view{
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    /* padding to advisory */
    
    
    /* .advisory-council1 .advisory {
        padding-top: 190px !important;
        padding-bottom: 100px !important;
    } */

    .advisorydesktop-view-para {
        display: none;
    }

    .advisory-council1 .council-para {
        font-weight: 700 !important;
        font-size: 10px !important;
        line-height: 14px !important;
    }

    /* .advisory-council1{
        padding-top: 100px;
    } */
}


/* For 480 Resolution */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .advisory-council1 .advisory {
        top: 65% !important;
    }

    .advisorymobile-view-para {
        display: block;
    }

    .advisory-council1 .advisory-heading {
        font-weight: 600;
        font-size: 52px;
        line-height: normal;
    }

    /* .advisory-council1 {
        background-image: url(../images/advisorycouncilbgmobile.webp);
        background-repeat: no-repeat;
        background-size: contain;
    } */

    /* .advisory-council1 .mobile-view{
        display: flex;
        justify-content: center;
        align-items: center;
    } */

    /* padding to advisory */
    
    
    /* .advisory-council1 .advisory {
        padding-top: 190px !important;
        padding-bottom: 100px !important;
    } */

    .advisorydesktop-view-para {
        display: none;
    }

    .advisory-council1 .council-para {
        font-weight: 700 !important;
        font-size: 14px !important;
        line-height: normal !important;
    }

    /* .advisory-council1{
        padding-top: 100px;
    } */
}

/* For 1670px Resolution */
/* @media  (min-device-width: 1920px)  and (max-device-width: 2250px)  
{ 
  
  .advisory-council1 {
        background-image: url(../images/advisorycouncil.webp) !important;
        background-repeat: no-repeat;
        background-size: cover !important;
     
    } 

}   */

@media (min-device-width: 1024px) and (max-device-width: 1080px) {
    .advisory-council1 {
        background-image: url(../images/advisorycouncil.webp);
        background-repeat: no-repeat;
        background-size: cover !important;
        min-height: 100%;
        height: 100% !important;

    }

    .advisory-council1 .advisory-heading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 82px;
        line-height: 100.3px;
        text-align: center;
        color: #122051;
    }

    /* padding to advisory */
    .advisory-council1 .advisory {
        /* padding-top: 350px !important;
        padding-bottom: 200px; */
    }

    .advisory-council1 .council-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #122051;

    }
}

@media (min-device-width: 1080px) and (max-device-width: 1280px) {

    /* .advisory-council1 {
        background-image: url(../images/advisorycouncil.webp);
        background-repeat: no-repeat;
        background-size: cover !important;
        min-height: 100%;
        height: 100% !important;

    } */

    .advisory-council1 .advisory-heading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 82px;
        line-height: 100.3px;
        text-align: center;
        color: #122051;


    }


    .advisory-council1 .advisory {
        /* padding-top: 350px;
        padding-bottom: 200px; */
    }

    .advisory-council1 .council-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;

        color: #122051;

    }
}
@media only screen and (min-width: 1680px) and (max-width: 1919px) {
    .advisory-council1 .advisory-heading {
        font-size: 86px;
        line-height: 106.3px;
    }
    .advisory-council1 .council-para {
        font-size: 19px;
        line-height: 27px;
    }
}
@media only screen and (min-width: 1920px) {
    .advisory-council1 .advisory-heading {
        font-size: 99px;
        line-height: 119.3px;
    }
    .advisory-council1 .council-para {
        font-size: 24px;
        line-height: 33px;
    }
}




/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    .advisory-council1 .advisory {
        top: 60%;
    }

    .advisory-council1 .advisory-heading {
        font-size: 76.8px;
        line-height: 94.4px;
        line-height: 118px;
    }

    .advisory-council1 .council-para {
        font-size: 16.8px;
        line-height: 23.2px;
     }

}


@media (min-width: 1280px) and (max-width: 1360px) {

    .advisory-council1 .advisory {
        top: 60%;
    }
 
    .advisory-council1 .advisory-heading {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }
    .advisory-council1 .council-para {
        font-size: 17.85px;
        line-height: 24.65px;
   }
}


@media only screen and (min-width: 1360px) and (max-width: 1680px) {
    .advisory-council1 .advisory {
        top: 60%;
    }
    
    .advisory-council1 .advisory-heading {
        font-size: 86.4px;
        line-height: 106.2px;
        line-height: 118px;
    }
    .advisory-council1 .council-para {
        font-size: 18.9px;
        line-height: 26.1px;
   }

}


@media only screen and (min-width: 1680px) and (max-width: 1919px) {
    .advisory-council1 .advisory {
        top: 60%;
    }
     .advisory-council1 .advisory-heading {
        font-size: 91.2px;
        line-height: 112.1px;
        line-height: 118px;
    }
    .advisory-council1 .council-para {
        font-size: 19.95px;
        line-height: 27.55px;
   }
}


