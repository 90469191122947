.meet-section {
  padding: 5% 0;
}

.meet-section h3.heading {
  /* padding: 0% 0 0 5%; */
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.04em;
  color: #122051;
}

.img-container {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  gap: 50px;
}

.img-expand {
  width: 30%;
  height: 600px;
  position: relative;
  margin-right: -2.5px;
  margin-left: -2.5px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 25px;
  overflow: hidden;
  object-fit: cover !important;
  background: #ffffff;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 25px;
  background: rgba(11, 11, 11, 0.60);
  mix-blend-mode: darken;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
}


.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
  z-index: 1;
  opacity: 0.4000000059604645;
  background: rgba(11, 11, 11, 0.60);
}

.img-expand img {
  width: 100% !important;
  height: 100%;
}

.img-expand.full {
  width:55%;
  transition: all .5s;
}


.img-expand .arrow {
  position: absolute;
  top: 25px;
  right: 25px;
  max-width: 34px;
  max-height: 34px;
  FONT-WEIGHT: 100;
  display: none;
  z-index: 2;
}

.img-expand .arrow img {
  width: 100%;
  height: 100%;
}

.img-expand.full .arrow {
  display: block;
}


.img-expand .text {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 5%;
  top: 10%;
  color: #ffffff;

}

/* .img-expand.full .text {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 5%;
  top: 70%;
  text-align: left;
  color: #ffffff;
} */


.img-expand .text {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0%;
  top: 50%;
  padding: 0;
  color: #ffffff;
  margin: auto auto;
  text-align: center;
  transform: translate(0, -50%) rotate(90deg);
  /* transition-delay:dispay 0.5s ,opacity 0.5s !important;
  transition-duration:dispay 0.5s ,opacity 0.5s !important; */

  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.5s, 0.5s;
  transition-property: display, opacity;
}

.img-expand.full .text {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0%;
  padding: 0 10%;
  top: 70%;
  color: #ffffff;
  margin: auto auto;
  text-align: left;
  transform: translate(0, 0) rotate(0deg);
  /* transition-delay:dispay 0.5s ,opacity 0.5s !important;
  transition-duration:dispay 0.5s ,opacity 0.5s !important; */

  transition-duration: 0.3s, 0.3s, 0s, 0s;
  transition-delay: 0.5s, 0.5s, 0s, 0s;
  transition-property: display, opacity, transform, top;
}



/* .img-expand.full .text {
  transform: rotate(0deg);
} */

/* .img-expand .text .head1{
  transform: rotate(90deg);
}*/

.img-expand.full .text .head2{
  /* transform: rotate(0deg); */
  position: absolute;
  top: 7px;
} 

.img-expand.hide-text .text {
  opacity: 0;
}

.img-expand .text {
  opacity: 1;
}

.img-expand.full.hide-text .text {
  opacity: 0;
}

.img-expand.full .text {
  opacity: 1;
}

.img-expand .text .head2 {
  /* transition:display 2s ; */
  display: none;

  /* transition-duration: 0.4s;
  transition-delay: 1s;
  transition-property: display; */
  opacity: 0;
}

.img-expand.full .text .head2 {
  /* transition:display 2s ; */
  display: block;
  opacity: 1;
}

.img-expand .text .head1 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1em;
  letter-spacing: 0.01em;
  white-space: nowrap;
  color: #FFFFFF;
}


.img-expand.full .text .head1 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 1em;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}


.img-expand.full .text .head2 {
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 90px;
  letter-spacing: 0.01em;
  color: #B2E219;
}
.mobileview-footer {
  display: none;
}

/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 1023px) {
  .mobileview-footer {
    display: block;
  }
  .meet-section .mobile-view-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px !important;
    line-height: 30px;
    margin-left: 5%;
    letter-spacing: 0.04em;

  }

  .mobileview-footer .footer-heading h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 22px;
    word-spacing: 0.3em;
    text-align: center;
    letter-spacing: 0.02em;
    color: #122051;
  }

  .mobileview-footer .footer-heading img {
    /* width: 30px; */
    height: 12px;
  }

  .mobileview-footer .contact-btn {

    font-weight: 600;
    font-size: 20px !important;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    padding: 20px 30px !important;
    color: #122051;

  }

  .img-expand.full .text .head1 {
    white-space: break-spaces;
  }
  
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

.swiper-div {
  position: relative;
}

.swiper-slide .arrow {
    position: absolute;
    top: 25px;
    right: 25px;
    max-width: 34px;
    max-height: 34px;
    FONT-WEIGHT: 100;
    z-index: 2;
}

.swiper-slide .arrow img {
  max-width: 24px;
  max-height: 24px;
  border-radius: 0px;
  width: 100%;
}
    
  .mobileswiper .text .head1{
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    line-height: 30px !important;
    letter-spacing: 0.01em;
    color: #FFFFFF !important;
  }

  .mobileswiper .text .head2{
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.01em;
    color: #B2E219 !important;
  }


  .mobileswiper .text {
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 0%;
    padding: 0 10%;
    top: 70%;
    color: #ffffff;
    margin: auto auto;
    text-align: left;
    transform: translate(0, 0) rotate(0deg);
    /* transition-delay:dispay 0.5s ,opacity 0.5s !important;
    transition-duration:dispay 0.5s ,opacity 0.5s !important; */

    transition-duration: 0.3s, 0.3s, 0s, 0s;
    transition-delay: 0.5s, 0.5s, 0s, 0s;
    transition-property: display, opacity, transform, top;
  }

  .swiper-div img{
    width: 100%;
    height: 30%;
    border-radius: 30px; 
    /* display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; */
  }
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    display: none;
  }


  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 320px;
  }
  
  .swiper-slide {
    width: 75% !important;
  }


  }

@media (min-width: 420px) and (max-width: 640px) {

  .swiper-slide img {
    max-height: 400px !important;
  }  
}


@media (min-width: 641px) and (max-width: 767px) {

  .swiper-slide img {
    max-height: 650px !important;
  }

}


@media (min-width: 768px) and (max-width: 1023px) {

  .swiper-slide img {
    max-height: 650px !important;
  }  

  

  .meet-section .mobile-view-heading {
    font-size: 35px !important;
    padding-left: 5%;
  }

  .mobileswiper .text {
    top: 75%;
  }

  .mobileswiper .text .head1 {
    font-size: 26px !important;
  }

  .mobileswiper .text .head2 {
    font-size: 24px !important;
  }

  
  .mobileswiper .text .head1 {
    font-size: 26px;
  }

  .mobileswiper .text .head1 {
    font-size: 24px;
  }

  .meet-section .mobileview-footer {
    display: block;
  }

}

@media (min-width: 1024px) and (max-width: 1280px) {
  
  .meet-section h3.heading {
    font-size: 38.4px;
    line-height: 47.2px;   
  }  

  .img-expand .text .head1, .img-expand.full .text .head1 {
    font-size: 25.6px;   
  }

  .img-expand.full .text .head2,
  .img-expand .text .head2 {
    font-size: 22.4px;   
  }

  .meet-section .desktop .img-container{
    gap: 20px;
  } 

  .meet-section .img-expand {
      width: 20%;
      max-height: 450px;
  }
  .meet-section .full.img-expand {
    width: 52%;
  }
  .meet-section .img-expand .text {
    left: -5%;
    top: 40%;
  }
  
  .img-expand.full .text {
    left: 0%;
    top: 70%;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1360px) {
  .meet-section .desktop .img-container{
    gap: 30px;
  } 

  .meet-section h3.heading {
    font-size: 40.8px;
    line-height: 50.15px;   
  }  

  .img-expand .text .head1, .img-expand.full .text .head1 {
    font-size: 25.6px;   
  }

  .img-expand.full .text .head2,
  .img-expand .text .head2 {
    font-size: 22.4px;
  }

  .meet-section .img-expand {
    width: 25%;
    max-height: 450px;
  }
  .meet-section .full.img-expand {
    width: 50%;
  }
  .meet-section .img-expand .text {
    left: -6%;
    top: 40%;
  }

  .img-expand.full .text {
    left: 0%;
    top: 70%;
  }

}

@media only screen and (min-width: 1360px) and (max-width: 1600px) {

  .meet-section .desktop .img-container{
    gap: 30px;
  } 

  .meet-section h3.heading {
    font-size: 43.2px;
    line-height: 53.1px;   
  }  

  .img-expand .text .head1, .img-expand.full .text .head1 {
    font-size: 26.8px;   
  }
  
  .img-expand.full .text .head2,
  .img-expand .text .head2 {
    font-size: 23.8px;
  }

  .meet-section .img-expand {
    width: 27%;
    max-height: 500px;
}
.meet-section .full.img-expand {
  width: 50%;
}
.meet-section .img-expand .text {
  left: -8%;
  top: 40%;
}

.img-expand.full .text {
  left: 0%;
  top: 70%;
}



}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .meet-section .desktop .img-container{
    gap: 30px;
  } 

  .meet-section h3.heading {
    font-size: 45.6px;
    line-height: 56.05px;   
  }  

  .img-expand .text .head1, .img-expand.full .text .head1 {
    font-size: 30.4px;   
  }
  .img-expand .text .head2 {
    font: 25.2px;
  }

}

@media only screen and (min-width: 1920px) {
  .meet-section .desktop .img-container{
    gap: 40px;
  } 
  .meet-section h3.heading {
    font-size: 52px;
    line-height: 62px;
   
  }  
  .tablinks h3.tab-heading {
   
    font-size: 24px;
    line-height: 29px;
    
  }

  .img-expand .text .head2 {
    font: 26.6px;
  }

}
