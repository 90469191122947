
.d-flex.member-images {
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #BEBEBE; */
}


.d-flex.member-images img {
  height: 100%;
  max-width: 160px;
  max-height: 100px;
}

.d-flex.member-images {
  /* justify-content: space-between;
  align-items: center; */
  border-bottom: 1px solid #BEBEBE;
}


.members-section .memmobile-view{
  display: none;
}

.members-section .mobileview-footer {
  display: none;
}
/* For 460 Resolution */  
@media only screen  and (min-device-width : 320px) and (max-device-width : 460px)  { 
  .memedesktop-view{
    display: none;
}
.members-section .memmobile-view{
  display: block;
}
.members-section .mobileview-footer {
  display: block;
}
  .d-flex.member-images img {
      height: 50% !important;
      width: 40px;
      margin: auto;
      /* padding: 0px 10px !important; */
  }
  /* .d-flex.member-images {
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #BEBEBE;
  } */
  .d-flex.member-images{
      padding-bottom: 20px;
      padding-top: 20px;
  }
  .mobileview-footer .footer-heading h3 {
      font-family: 'Clash Display';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 22px;
      /* or 69% */
      word-spacing: 0.3em;
    
      text-align: center;
      letter-spacing: 0.02em;
    
      color: #122051;
    }
    
    .mobileview-footer .footer-heading img {
      /* width: 25px; */
      height: 12px;
    }
    /* .memmobile-view{
      margin-bottom: 200px !important;
      padding-bottom: 300px !important;


    } */
    /* .mobileview-footer{
      z-index: 20 !important;
      position: relative;
    } */
    .mobileview-footer .contact-btn {
    
      font-weight: 600;
      font-size: 20px !important;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.02em;
    padding: 20px 30px !important;
      color: #122051;
    
    }
    
}/* For 640 Resolution */  

@media only screen  and (min-device-width : 461px) and (max-device-width : 767px)  { 
  .memmobile-view{
    display: block;
  }
  .members-section .mobileview-footer {
    display: block;
  }
  .members-section .memedesktop-view{
    display: none;
  }
  
  .d-flex.member-images img {
    height: 50% !important;
    width: 50px;
    margin: auto;
    /* padding: 0px 10px !important; */
  }
    
  .d-flex.member-images {
    /* justify-content: space-between;
    align-items: center; */
    border-bottom: 1px solid #BEBEBE;
  }
    
  .d-flex.member-images{
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .mobileview-footer .footer-heading h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 22px;
    /* or 69% */
    word-spacing: 0.3em;
      
    text-align: center;
    letter-spacing: 0.02em;
      
    color: #122051;
  }
      
  .mobileview-footer .footer-heading img {
    /* width: 25px; */
    height: 12px;
  }
      /* .memmobile-view{
        margin-bottom: 200px !important;
        padding-bottom: 300px !important;
  
  
      } */
      /* .mobileview-footer{
        z-index: 20 !important;
        position: relative;
      } */
  
  .mobileview-footer .contact-btn {
    font-weight: 600;
    font-size: 20px !important;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    padding: 20px 30px !important;
    color: #122051;   
  }
      
  }


  @media only screen  and (min-device-width : 768px) and (max-device-width : 1023px)  { 
    .members-section  .memmobile-view{
      display: block;
    }
    .members-section .mobileview-footer {
      display: block;
    }
    .members-section .memedesktop-view{
      display: none;
    }
    
    .d-flex.member-images img {
      height: 50% !important;
      width: 75px;
      margin: auto;
      /* padding: 0px 10px !important; */
    }
      
    .d-flex.member-images {
      /* justify-content: space-between;
      align-items: center; */
      border-bottom: 1px solid #BEBEBE;
    }
      
    .d-flex.member-images{
      padding-bottom: 20px;
      padding-top: 20px;
    }
  
    .mobileview-footer .footer-heading h3 {
      font-family: 'Clash Display';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 22px;
      /* or 69% */
      word-spacing: 0.3em;
        
      text-align: center;
      letter-spacing: 0.02em;
        
      color: #122051;
    }
        
    .mobileview-footer .footer-heading img {
      /* width: 25px; */
      height: 12px;
    }
        /* .memmobile-view{
          margin-bottom: 200px !important;
          padding-bottom: 300px !important;
    
    
        } */
        /* .mobileview-footer{
          z-index: 20 !important;
          position: relative;
        } */
    
    .mobileview-footer .contact-btn {
      font-weight: 600;
      font-size: 20px !important;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.02em;
      padding: 20px 30px !important;
      color: #122051;   
    }
        
    }

/* @media  (min-width: 1024px)  and (max-width: 1280px)   {
  .memmobile-view{
      display: none;
  }
  .mobileview-footer {
      display: none;
    }

} */
/* @media only screen  and (min-device-width : 320px) and (max-device-width : 980px)  {
  .memedesktop-view{
      display: none;
  }
  
} */