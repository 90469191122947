header.header-insights {
    width: 100%;
    height: 100% !important;
    min-height: auto !important;
    height: auto;
    background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
    background: url('../images/header-insights.webp') no-repeat top left;
    background-size: contain;
    background:none !important;   
    position: relative;
}

header.header-insights .gif-image img {
    max-height: 310px;
}


header.header-insights .bg,
header.header-insights .bg img {
    width: 100%;
    max-width: 100vw;
    height:auto;
}

header.header-insights .hero {
    position: absolute;
    top:70%;
}

header.header-insights .hero-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}


header.header-insights .hero-content .headerinsightgif {
    max-width: 60%;
    width: 100%;
    margin: auto;
}

header.header-insights .hero-text {
    position: relative;
    text-align: center;
}

header.header-insights .hero-text img {
    max-height: 26px;
    width: auto;
}

header.header-insights .text-box {
    padding: 1.5rem 0px;
}

header.header-insights .hero-text h2 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 74px;
    text-align: center;
    color: #BEBEBE;
}

header.header-insights .hero-text h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    text-align: center;
    letter-spacing: 0.01em;
    align-self: center;
    color: #FFFFFF;
}

header.header-insights .hero-text p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
}

/* header.header-insights .navbar-nav a {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #B2E219;
} */

header.header-insights .text-slide {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    transform-origin: center;
    padding: 0px 0px;
    height: 110px;
    /* object-fit: cover; */
}

header.header-insights .text-slide {
    overflow: hidden;
}

header.header-insights .text-slider {
    display: flex;
    vertical-align: top;
    justify-content: center;
    min-height: 110px;
    margin-bottom: 20px;
}


header.header-insights .text-slider.gap {
    gap: 20px;
}

header.header-insights .text-slider img {
    height: 100%;
    width: auto;
    align-self: center;
}



/* @media only screen  and (min-device-width : 320px) and (max-device-width : 480px)  { 
    header.header-insights {
        min-height: 70vh;
        height: 70vh;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); 
        background: url('../images/headerbg-mb.webp') no-repeat top left;
        background-size: cover;
    }

} */

@media only screen and (min-device-width : 320px) and (max-device-width : 335px) {

    header.header-insights .hero {
        position: absolute;
        top:70%;
    }

    header.header-insights .gif-image img {
        max-height: 90px;
    }
    
    /* header.header-insights .hero-content {
        position: absolute;
        top: 62% !important;
    }    */
}

@media only screen and (min-device-width : 335px) and (max-device-width : 380px) {
    header.header-insights .hero-content {
        position: absolute;
        top: 65% !important;
    } 
    
    header.header-insights .gif-image img {
        max-height: 100px;
    }

}


@media only screen and (min-device-width : 360px) and (max-device-width : 380px) {
    header.header-insights {
        background-size: cover !important;
    }

    header.header-insights .hero-content {
        top: 68% !important;
    } 

}
/* @media only screen and (min-device-width : 320px) and (max-device-width : 360px) {

    header.header-insights {
        height: 40vh;
        background-size: contain !important;
    }

    header.header-insights .hero-content {
        position: absolute;
        top: 65%;
    }

} */


/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    header.header-insights {
        /* min-height: 40vh;
        height: 40vh; */
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/header-insights-mb.webp') no-repeat top left;
        background-size: contain;
        background: none;
    }


    header.header-insights .hero {
        position: absolute;
        top:70%;
    }

    header.header-insights .hero-content .headerinsightgif {
        max-width: 75%;
    }
    
    
    header.header-insights .hero-content {
        position: absolute;
        top: 60%;
    }

    header.header-insights .text-box {
        padding: 10px 0 0;
    }

    header.header-insights .hero-text h2 {
        font-weight: 600;
        text-align: center;
        font-size: 18px;
        line-height: 20px;
        max-width: 175px;
        margin: auto;
    }

    header.header-insights .hero-text p {
        font-size: 10px;
        line-height: 12px;
    }

    header.header-insights .hero-text img {
        width: 20px;
        height: auto;
    }


}


/* @media only screen and (min-device-width : 420px) and (max-device-width : 480px) {

    header.header-insights {
        min-height: 35vh;
        height: 40vh !important;
    }

    header.header-insights .hero {
        position: absolute;
        top:70%;
    }
} */

/* @media only screen and (min-device-width : 450px) and (max-device-width : 480px) {

    header.header-insights {
        min-height: 35vh;
        height: 45vh !important;
    }   

} */


@media only screen and (min-device-width : 381px) and (max-device-width : 480px) {

    header.header-insights {
        /* min-height: 35vh;
        height: 38vh;
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%); */
        background: url('../images/header-insights-mb.webp') no-repeat top left;
        background-size: cover;
        background: none;
    }

    header.header-insights .gif-image img {
        max-height: 110px;
    }


    header.header-insights .hero {
        position: absolute;
        top:70%;
    }


    header.header-insights .hero-content {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    header.header-insights .text-box {
        padding: 10px 0 0;
    }

    header.header-insights .hero-text h2 {
        font-weight: 600;
        text-align: center;
        font-size: 18px;
        line-height: 20px;
        max-width: 175px;
        margin: auto;
    }

    header.header-insights .hero-text p {
        font-size: 10px;
        line-height: 12px;
    }

    header.header-insights .hero-text img {
        width: 20px;
        height: auto;
    }

}




@media only screen and (min-device-width : 481px) and (max-device-width : 640px) {
    header.header-insights .hero-content {   
        top: 60% !important;
    }

    header.header-insights .hero {
        position: absolute;
        top:70%;
    }

    header.header-insights .hero-content .headerinsightgif {
        max-width: 75%;
    }


    header.header-insights .gif-image img {
        max-height: 135px;
    }

}

@media only screen and (min-device-width : 640px) and (max-device-width : 768px) {
    
    header.header-insights .hero-content {   
        top: 70% !important;
    }
    header.header-insights {
        /* background-size: cover !important; */
        background: none;
    }

    header.header-insights .gif-image img {
        max-height: 135px;
    }

    header.header-insights .gif-image img {
        max-height: 145px;
    }

    header.header-insights .hero-content .headerinsightgif {
        max-width: 75%;
    }

}

/* For 640 Resolution */
@media only screen and (min-device-width : 481px) and (max-device-width : 768px) {

    header.header-insights {
        /* min-height: 70vh;
        height: 70vh; */
        background: linear-gradient(187deg, rgba(0, 0, 0, 1) 5.67%, rgba(0, 0, 0, 0.488542) 25.88%, rgba(0, 0, 0, 0) 60.38%);
        background: url('../images/header-insights-mb.webp') no-repeat top left;
        background-size: contain;
        background: none;
    }

    header.header-insights .hero-content {   
        top: 65%;
    }

    header.header-insights .hero {
        position: absolute;
        top: 75%;
    }

    header.header-insights .hero-text h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 19px;
    }

    header.header-insights .hero-text p {
        font-size: 10px;
        line-height: 12px;
    }

    header.header-insights .hero-text img {
        width: 20px;
        height: auto;
    }

    header.header-insights .text-box {
        padding: 20px 0 0;
    }


}



@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* STYLES GO HERE */
}

/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1370px) {
    

    header.header-insights .hero-text h2 {
        font-size: 51px;
        line-height: 62.9px;
    }

    header.header-insights .hero-text h3 {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }
}




/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    header.header-insights .gif-image img {
        max-height: 250px;
    }
    
    header.header-insights .hero-text h2 {
        font-size: 48px;
        line-height: 59.2px;
    }

    header.header-insights .hero-text h3 {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }
    
}


@media (min-width: 1280px) and (max-width: 1360px) {
    header.header-insights .gif-image img {
        max-height: 280px;
    }

    header.header-insights .hero-text h2 {
        font-size: 51px;
        line-height: 62.9px;
    }

    header.header-insights .hero-text h3 {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }
}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {
    
    header.header-insights .gif-image img {
        max-height: 290px;
    }

    header.header-membeni .hero-content {
        top: 70%;
    }
    header.header-insights .hero-text h2 {
        font-size: 54px;
        line-height: 66.6px;
    }

    header.header-insights .hero-text h3 {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }

}

@media only screen and (min-width: 1280px) {

    header.header-insights  {
        background-size: cover;
    }    
    header.header-insights .hero-content {
        top:70%;
    }    
}

@media only screen and (min-width: 1600px) {

    header.header-insights .hero-content {
        top:75%;
    }    
}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {

    header.header-insights .gif-image img {
        max-height: 300px;
    }

    header.header-insights .hero-text h2 {
        font-size: 57px;
        line-height: 70.3px;
    }

    header.header-insights .hero-text h3 {
        font-size: 81.6px;
        line-height: 100.3px;
        line-height: 118px;
    }
}




