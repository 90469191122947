footer {
    background: #ffffff;
    height: 100%;
    position: relative;
    overflow: hidden;
    
}

.footer-bg {
    background: url('../images/footerbg.webp') no-repeat;
    background-position: top center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 0;
}

.footer-bg.amina {
    top: 0 !important;
}

footer .slide-up {
    opacity: 0;
    transform: translateY(50px);
}

footer .slide-up .contact-btn {
    transition:all 0.5s;
    border:1px solid #B2E219;
}

footer .slide-up .contact-btn:hover {
    background: transparent;
    color: #B2E219;
}


footer .container-fluid {
    height: 100vh;
    align-self: middle;
    position: relative;
    background: transparent;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.footer .footer-heading h3 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 90px;
    text-align: center;
    color: #FFFFFF;
    word-spacing: 20px;
}

.footer-hr {
    width: 0px;
    border: 1px solid #FFFFFF;
}


.slide-up2 {
    opacity: 0;
    transform: translateY(25px);
}

.slide-up3 {
    opacity: 0;
    transform: translateY(15px);
}

.footer-logo-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
}

.footer-logo-container img {
    width: 100%;
    max-width: 60px;
}

.footer-logo-container h4 {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.footer-logo-container h5 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: left;
    color: #B2E219;
}

input.newsletter-input {
    width: 100%;
    border-radius: 35px;
    padding-left: 35px;
    max-width: 360px;
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
    letter-spacing: 0.04em;
    color: #C4C4C4;
    border: none;
}

input.newsletter-input:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline: none;
}

.newsletter-btn {

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #122051;
    padding: 13px 30px;
    background: #B2E219;
    border: 1px solid #B2E219;
    border-radius: 100px;
    transition: 0.5s;
}

footer .newsletter-btn:hover {
    background: transparent;
    color: #B2E219;
}



footer ul.footer-menu {
    padding:0px;
    display: flex;
    justify-content: space-between;
}

footer ul.footer-menu li {
    /* margin:0px 10px; */
    display: inline-flex;
    gap: 0px;
}

footer ul.footer-menu li a {    
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-decoration: none;
    text-align: center;
    color: #FFFFFF;
    margin:0px 16px;
    /* padding-right: 70px; */
    /* padding-left: -10px; */
    /* gap: 5px; */
}


.copytight-text {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}


footer ul.social-icons {
    padding:0px;
    display: flex;
    justify-content: center;
}

footer ul.social-icons li {
    margin:0px 20px;
    display: inline-flex;
    gap: 10px;
}

footer hr {
    border-color: rgba(241, 21, 118, 0.4);
    opacity: 1;
    margin-top: 0.5rem;
}

.hr-first {
    margin-left: 10%;
}

.footermobile-view {
    display: none;
}

.mobileview-footer {
    display: none;
}    
.errormsg{
    color: red;
    text-align: left;
}

footer  p.form-success {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    letter-spacing: 0.02em;
    color: #ffffff;
}


@media only screen  and (min-device-width : 320px) and (max-device-width : 767px)  {
    footer .container-fluid.desktop, footer .footer-bg { 
        display: none;
    }    

    .footerdesktop-view{
        display: none;
    }
    
}



@media only screen  and (min-device-width : 320px) and (max-device-width : 767px)  {

.mobileview-footer {
    display: block;
}    

.footer {
    background-image: url('../images/footer-bg-mb.webp');
    background-repeat: no-repeat;
    background-size: cover;
}
/* .footermobile-view .footer-logo-container {
    display: flex
} */

/* .footer-logo-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
} */

.footermobile-view {
    display: block;
}

.footer-logo-container h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: left;;
}

.footer-logo-container h5 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 14px !important;
    line-height: 17px !important;
    text-align: left;
    color: #B2E219 !important;
    
}


footer ul.footer-menu {
    padding-left:20px;
    display: block;
}

footer ul.footer-menu li {
    /* margin:0px 10px; */
    display: block;
}

footer ul.footer-menu li a {    
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
}


.marginright{
    margin-right: 10px !important;
}
.footermobile-view .form-control{
    border-radius: 40px !important;
    padding-bottom: 8px;
    padding-top: 8px;
    /* padding: 8px ; */
}

.contact-btn{
    padding-bottom: 8px;
    padding-top: 8px;
}

.footermobile-view ul.footer-menu li{
   padding-bottom: 30px !important;
   list-style-type: none;
} 
.footermobile-view ul.footer-menu li a {    
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
   
    color: #FFFFFF;
    text-decoration: none;
    /* padding-right: 70px; */
    /* padding-left: -10px; */
    /* gap: 5px; */
}
.footermobile-view .newsletter-btn{
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.04em !important;
    }

.footer-logo-container img{
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    }   

}



@media only screen  and (min-device-width : 320px) and (max-device-width : 365px)  {
    .footer-logo-container {
        gap: 0px;
    }

    .footer-logo-container img{
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    .footer-logo-container h4 {
        font-size: 22px;
        line-height: 28px;
    }

    .footer-logo-container h5 {
        font-size: 12px !important;
        line-height: 15px !important;
    }

    .marginright {
        margin-right:0px;
        padding: 0px;
    }

    footer ul.footer-menu {
        padding-left: 0px;
    }
}

@media only screen  and (min-device-width : 768px) and (max-device-width : 1023px)  {

    .mobileview-footer {
        display: block;
    }    

    footer .desktop.container-fluid {
        display: none;
    }
    
    .footer {
        background-image: url('../images/footer-bg-mb.webp');
        background-repeat: no-repeat;
        background-size: cover;
    }
    /* .footermobile-view .footer-logo-container {
        display: flex
    } */
    
    /* .footer-logo-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    } */
    
    .footermobile-view {
        display: block;
        max-width: 650px;
        margin: auto;
    }
    
    .footer-logo-container h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: left;;
    }
    
    .footer-logo-container h5 {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px !important;
        line-height: 17px !important;
        text-align: left;
        color: #B2E219 !important;
        
    }
    
    
    footer ul.footer-menu {
        padding-left:20px;
        display: block;
    }
    
    footer ul.footer-menu li {
        /* margin:0px 10px; */
        display: block;
    }
    
    footer ul.footer-menu li a {    
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        margin: 0px;
    }
    
    
    .marginright{
        margin-right: 10px !important;
    }
    .footermobile-view .form-control{
        border-radius: 40px !important;
        padding-bottom: 8px;
        padding-top: 8px;
        /* padding: 8px ; */
    }
    
    .contact-btn{
        padding-bottom: 8px;
        padding-top: 8px;
    }
    
    .footermobile-view ul.footer-menu li{
       padding-bottom: 30px !important;
       list-style-type: none;
    } 
    .footermobile-view ul.footer-menu li a {    
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 22px;
       
        color: #FFFFFF;
        text-decoration: none;
        /* padding-right: 70px; */
        /* padding-left: -10px; */
        /* gap: 5px; */
    }
    .footermobile-view .newsletter-btn{
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 600 !important;
        font-size: 18px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.04em !important;
        }
    
    .footer-logo-container img{
        margin: auto;
        padding-left: 10px;
        padding-right: 10px;
        }   
    
    }

@media (min-width: 1024px) and (max-width: 1280px) {
    
    .footer .links-row .col-md-10{
        width: 100%;
    }
    .footer .links-row .col-md-1{
        width: 0%;
    }


    .footer ul.footer-menu li a {
        font-size: 14px;
        line-height: 17px;
    }
} 

@media only screen  and (min-device-width : 768px) and (max-device-width : 1023px)  {

}

/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1600px) {
    
    .footer .footer-heading h3 {
        font-size: 81.2px;
    }

    footer .footer-heading img {
        height: 30px;
    }

    .footer .form-row.row {
        width: 100%;
        justify-content: space-between !important;
        align-items: center;
    }     

    .footer .form-row .col-md-1 {
        width: 0px;
    }
    
    .footer .form-row.row .col-md-6.mx-5.mt-3.col {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 48%;
    }

    .footer .form-row.row .col-md-4 {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 48%;
    }


    .footer .contact-btn {
        margin-top: 30px !important;
        padding: 25px 55px;
    }

    .footer-logo-container {
        gap: 10px;
        align-items: flex-start;
    }

    .footer-logo-container h4 {
        font-size: 25.5px;
        line-height: 31.45px;
        text-align: left;
    }

    
    .footer-logo-container h5 {
        font-size: 14.45px;
        line-height: 17px;
    }

    .footer input.newsletter-input {
        height: 45px;
        padding-left: 20px;
    }

    .footer .contact-btn.newsletter-btn {
        margin-top:0px !important;
        padding: 10px 30px !important;
        white-space: nowrap;
    }
}



/* footer .footer-heading img {
    height: 30px;
} */

/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    .footer .footer-heading h3 {
        font-size: 76.8px;
        line-height: 72px;
        font-size: 70px;
        line-height: 65px;
    }

    footer .contact-btn {
        padding: 25.6px 53.6px;
        font-size: 25.6px;
        line-height: 31.2px;
    }    

    footer .contact-btn.newsletter-btn {
        font-size: 16px;
        line-height: 20px;
    }
}


@media (min-width: 1280px) and (max-width: 1360px) {
    
    .footer .footer-heading h3 {
        font-size: 81.8px;
        line-height: 76.5px;
    }

    footer .contact-btn {
        padding: 27.2px 56.95px;
        font-size: 27.2px;
        line-height: 33.5px;
    }    
    footer .contact-btn.newsletter-btn {
        font-size: 16px;
        line-height: 20px;
    }

}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {
    

    .footer .footer-heading h3 {
        font-size: 86.4px;
        line-height: 81px;
    }

    footer .contact-btn {
        padding: 28.8px 60.3px;
        font-size: 28.8px;
        line-height: 35.1px;
    }   
    .footer .contact-btn.newsletter-btn {
        font-size: 16px;
        line-height: 20px;
    }    
}



@media only screen and (min-width: 1600px) and (max-width: 1919px) {
    
    .footer .footer-heading h3 {
        font-size: 91.2px;
        line-height: 85.5px;
    }

    footer .contact-btn {
        padding: 30.4px 63.65px;
        font-size: 30.4px;
        line-height: 37.05px;
    }    

    .footer .contact-btn.newsletter-btn {
        font-size: 16px;
        line-height: 20px;
        margin-top: 0px !important;
        padding: 10px 30px !important;
        white-space: nowrap;
    }
}








