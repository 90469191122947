.blog-section {
    width: 100%;
    max-width: 1440px;
    padding: 50px 20px;
    text-align: center;
    margin: auto;
}

.blog-list {
    display: flex;
    justify-content: left;
    max-width: 1200px;
    margin: auto;
    gap: 50px;
    flex-wrap: wrap;
}

.single-blog {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 45%;
    flex-wrap: wrap;
    padding: 35px 15px 15px 35px;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.single-blog a {
    z-index: 2;
    text-decoration: none;
}

.single-blog .blog-content img {
    width: 100%;
    max-height: 235px;
}

.d-flex {
    display: flex;
}

.gap-x-30 {
    column-gap: 30px;
}

.overlay-box {
    position: relative;
    transform: translate(-10px ,-10px);
}

.green-bg {
    position: absolute;
    left: 0;
    top: 3px;
    z-index: -1;
    transform: scale(1);
    transition: all 0.5s ease;
}

.blog-content {
    z-index: 1;
}

.blog-content h2 {
    font-family: 'Gotham';
    font-style: normal;
    text-align: left;
    font-weight: 700;
    font-size: 21px;
    line-height: 114.2%;
    color: #122051;
}

.blog-content p {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    text-align: left;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.single-blog:hover .overlay-box .green-bg {
    transform: scale(60);
}


/* pagiantion */

.pagination {
    max-width: 1200px;
    margin: auto;
}

.pagination ul.paginationBttns {
    padding: 0px;
    list-style: none;
    display: flex;
    gap: 30px;
    justify-content: flex-end;
}
.pagination li {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 20px;
    text-align: center;
    color: #BEBEBE;
}

.pagination li.paginationActive {
    position: relative;
    color: #122051;
}

.pagination li.paginationActive:before {
    content: "";
    width: 1px;
    background: #B2E219;
    position: absolute;
    height: 60px;
    top: -100%;
    left: -100%;
    border-right: 33px solid #B2E219;
    border-radius: 0px 50px 50px 0px;
    z-index: -1;
}

.pagination li.paginationDisabled {
    display: none;
}




@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  
     .blog-section {
      display: block;
    }

    .blog-list {
        gap: 20px;
    }
    .blog-section .single-blog {
      width: 95%;
      margin:20px auto;
      padding: 25px 15px 15px 25px;  
    }

    .blog-section .single-blog .blog-content img {
        max-height: unset;
    }

    .blog-section .single-blog h2 {
      font-size: 12px;
    }

    .blog-section .single-blog .blog-content p {
        display: none;
    }
    .blog-section .single-blog .overlay-box img, .blog-section .overlay-box svg {
      width: 30px;
    }

    .blog-section .single-blog .overlay-box .green-bg {
       top:-2px;
       left:0px;     
    }
  }


  
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {
    .blog-section .blog-list {
        gap: 30px;
    }
    
    .blog-section .blog-list .single-blog {
         width: 47%;
         padding: 25px 15px 15px 25px;
    
    }
    
    .blog-section .blog-list .single-blog .blog-content h2 {
        font-size:16px;
    }
}
  

 
/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    .blog-section .blog-list .blog-content h2 {
        font-size: 16.8px;
    }

    .tablinks h3.tab-heading {
      font-size: 16.8px;
      line-height: 20.8px;
    }

    .blog-section .blog-list .blog-content p {
        font-size: 12.8px;
        line-height: 15.2px;
    }
  }
  
  
  @media only screen and (min-width: 1280px) and (max-width: 1360px) {

    .blog-section .blog-list .blog-content h2 {
        font-size: 17.85px;
    }

    .blog-section .blog-list .blog-content p {
        font-size: 13.6px;
        line-height: 15.12px;
    }

}
  
  @media only screen and (min-width: 1360px) and (max-width: 1600px) {

    .blog-section .blog-list .blog-content h2 {
        font-size: 18.9px;
    }

    .blog-section .blog-list .blog-content p {
        font-size: 14.4px;
        line-height: 17.1px;
    }
  }
  
  
  @media only screen and (min-width: 1600px) and (max-width: 1919px) {
    
    .blog-content h2 {
        font-size: 19.95px;
    }
    .blog-content p {
        font-size: 15.2px;
        line-height: 18.05px;
    }
  }
  

  @media only screen and (min-width: 1920px) {
    
    .blog-content h2 {
        font-size: 24px;
    }
    .blog-content p {
        font-size: 18px;
        line-height: 21px;
    }
  }
  