.about-section .section-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: #122051;
}

.desktopview h3 {

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    line-height: 118px;
    letter-spacing: 0.02em;

    color: #BEBEBE;

}
.about-container .right-box img{
    height: 699px;
}
.about-container .visionimg{
transform: translateY(-30px);
}
.about-section .top-margin {
    margin-top: 130px;
}
.about-container .carousel-indicators{
   margin-left: 0px;
    justify-content: flex-start;
    
}
.about-section .who-we-are-sect {
    background-image: url('../images/headerbg.webp');
    background-repeat: no-repeat;
    background-size: cover;
}

.about-section .about-sub-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 89px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.02em;
    color: #BEBEBE;
    padding-top: 30px;
}

.about-section .about-sub-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px !important;

    /* or 105% */

    text-align: center;

    color: #FFFFFF;

}

.about-section .about-sub-para2 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 7px;
    /* or 105% */

    text-align: center;

    color: #FFFFFF;

}

.about-section .about-list {
    display: flex;
    margin: auto;
}

.about-section .list {
    margin: auto;
}

.about-section .list li {
    margin: auto;
    padding-right: 20px;
    list-style: none;
}

.about-section .about-list h4 {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
    padding-top: 35px;

}

/* slider */
.about-section .about-slider .slider-heading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: normal;
    text-align: center;

    color: #122051;

}

.about-slider img {
    display: flex;
    margin: auto;
    margin-bottom: 40px;
    padding-bottom: 50px;
    height: 490px !important;
}

/* .about-slider .sliderimg{
    height: 100%;
    max-height: 100vh;
} */
.about-section .about-slider .slider-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 29px;
    /* or 104% */

    text-align: center;

    color: #122051;

}

.about-section .carousel-control-prev,
.about-section .carousel-control-next {
    display: none;
}

.about-section .carousel-indicators button {
    background-color: #B2E219 !important;
    border: 1px solid #B2E219;
    border-radius: 200px !important;
    width: 10px;
    height: 10px;
}

.about-section .text-slide {
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    transform-origin: center;
    padding: 0px 0px;
    height: 110px;
    display: flex;
    margin: auto;
    position: relative;
    /* object-fit: cover; */
}

.about-section .text-slide img{
       max-height: 110px;
       width: 33px;
    margin-top: 5px;
} 

.about-section .gif-image img {
    width: 100%;
    height: auto !important;
}

/* .about-section .text-slide .headleft1{
    position: absolute;
    top: 10%;
    margin-top: 10px;
} */

/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 767px) {

    .about-section .visionimgmobile, 
    .about-section .visionimgmobile {
        height: 336px;
    }
 
    .mission-vision-slider .carousel-indicators{
        transform: translateY(40px);
    }
    .about-section .who-we-are-sect {
        background-image: url('../images/footer-bg-mb.webp');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about-section .desktopview,
    .about-slider.desktopview {
        display: none;
    }

    .about-section .mobileview-footer {
        display: block;
    }

    .about-section {
        overflow-x: hidden;
    }

    .mobileview .mission h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        letter-spacing: 0.02em;
        color: #BEBEBE;
    }

    .mobileview .mobileviewimg img {
        margin-top: -19px;
    }

    .mobileview .section-para {
        margin-top: -5px !important;
    }

    .mobileview .mission h3 span img {
        height: 11px;
        width: 23px;
        margin: 0px 2px;
    }

    .mobileview .section-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 129%;
        text-align: center;
        color: #122051;
    }

    .about-section .about-sub-heading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.02em;

        color: #BEBEBE;
        margin-top: -35px !important;
        margin-bottom: -10px !important;

    }

    .about-section .about-sub-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        /* or 136% */

        text-align: center;
        margin-left: 20px;
        padding-left: 30px !important;
        margin-right: 20px !important;
        padding-right: 50px !important;

        color: #FFFFFF;
    }

    .about-section .about-sub-para2 {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px !important;
        /* or 136% */

        text-align: center;

        color: #FFFFFF;
    }

    .about-section .mobileview .social-icon h4 {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        padding-top: 10px;
        color: #FFFFFF;
    }

    .about-section .mobileview .social-icon img {
        width: 100px;
    }

    .about-section .mobileview .padding-social-icon-left {
        padding-left: 20px !important;
        margin-left: 10px !important;

    }

    .about-section .mobileview .padding-social-icon-right {
        padding-right: 20px !important;
        margin-right: 10px !important;
    }

    .about-section .about-slider .slider-heading {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        /* or 97% */


        color: #122051;

    }

    /* .about-section .about-slider {
        margin-top: -120px !important;
       
    } */

    .about-section .about-slider img {
        max-width: 320px;
        width: 100%;
        height: auto !important;
    }

    .about-section .about-slider .carousel-indicators button {
        width: 4px;
        height: 4px;
        margin-top: -40px;
    }

    .about-section .about-slider .Carouselsection {
        margin-top: 22px;
    }

    /* .about-slider.desktopview{
        display: none;
    } */
    .about-slider .slider-mobileview-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        /* or 125% */

        text-align: center;
        margin-top: -90px;

        color: #122051;
    }

    .mobileview-footer .footer-heading h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        /* or 69% */
        word-spacing: 0.3em;

        text-align: center !important;
        letter-spacing: 0.02em;

        color: #122051;
    }

    .mobileview-footer .footer-heading img {
        /* width: 30px; */
        height: 12px;
    }

    .mobileview-footer .contact-btn {

        font-weight: 600;
        font-size: 16px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 15px 40px !important;
        color: #122051;

    }

}


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

    .about-section .visionimgmobile, 
    .about-section .visionimgmobile {
        /* height: 336px; */
        width: 50%;
    }

    .about-section .mobileview-footer {
        display: block;
    }
 
    .mission-vision-slider .carousel-indicators{
        transform: translateY(40px);
    }
    .about-section .who-we-are-sect {
        background-image: url('../images/footer-bg-mb.webp');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about-section .desktopview,
    .about-slider.desktopview {
        display: none;
    }

    .about-section {
        overflow-x: hidden;
    }

    .mobileview .mission h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 76px;
        line-height: normal;
        letter-spacing: 0.02em;
        color: #BEBEBE;
    }

    .mobileview .mobileviewimg img {
        margin-top: -19px;
    }

    .mobileview .section-para {
        margin-top: -5px !important;
    }

    .mobileview .mission h3 span img {
        /* height: 11px; */
        width: 60px;
        margin: 0px 2px;
    }

    .mobileview .section-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 129%;
        font-size: 22.4px;
        line-height: 33.6px;
        text-align: center;
        color: #122051;
    }

    .about-section .about-sub-heading {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.02em;

        color: #BEBEBE;
        margin-top: -35px !important;
        margin-bottom: -10px !important;

    }

    .about-section .about-sub-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height:normal !important;
        /*136% or 19px */
        font-size: 22.4px;
        /* line-height: 33.6px; */

        text-align: center;
        margin-left: 20px;
        padding-left: 30px !important;
        margin-right: 20px !important;
        padding-right: 50px !important;

        color: #FFFFFF;
    }

    .about-section .about-sub-para2 {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px !important;
        /* or 136% */

        text-align: center;

        color: #FFFFFF;
    }

    .about-section .mobileview .social-icon h4 {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        padding-top: 10px;
        color: #FFFFFF;
    }

    .about-section .mobileview .social-icon img {
        width: 100px;
    }

    .about-section .mobileview .padding-social-icon-left {
        padding-left: 20px !important;
        margin-left: 10px !important;

    }

    .about-section .mobileview .padding-social-icon-right {
        padding-right: 20px !important;
        margin-right: 10px !important;
    }

    .about-section .about-slider .slider-heading {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        /* or 97% */
        font-size: 22.4px;
        line-height: normal;


        color: #122051;

    }

    /* .about-section .about-slider {
        margin-top: -120px !important;
       
    } */

    .about-section .about-slider img {
        max-width: 320px;
        width: 100%;
        height: auto !important;
    }

    .about-section .about-slider .carousel-indicators button {
        width: 4px;
        height: 4px;
        margin-top: -40px;
    }

    .about-section .about-slider .Carouselsection {
        margin-top: 22px;
    }

    /* .about-slider.desktopview{
        display: none;
    } */
    .about-slider .slider-mobileview-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        /* or 125% */
        font-size: 18px;
        line-height: normal;
        text-align: center;
        margin-top: -90px;

        color: #122051;
    }

    .mobileview-footer .footer-heading h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        /* or 69% */
        word-spacing: 0.3em;

        text-align: center !important;
        letter-spacing: 0.02em;

        color: #122051;
    }

    .mobileview-footer .footer-heading img {
        /* width: 30px; */
        height: 12px;
    }

    .mobileview-footer .contact-btn {

        font-weight: 600;
        font-size: 16px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 15px 40px !important;
        color: #122051;

    }


    .who-we-are-sect .desktopview {
        display: block;
    }

    .who-we-are-sect .desktopview .col-md-1 {
        display: none;
    }

    .who-we-are-sect .mobileview {
        display: none;
    }

    .about-section .list ul.about-list {
        padding: 0px;
        list-style: none;
        gap: 20px;
    }

    .about-section .list ul.about-list li {
        padding-right: 0px;
        padding-left: 0px;    
        text-align: center;
    }

    .about-section .list ul.about-list li img {
        width: 100px;
    }

    .about-section .list ul.about-list li h4 {
        font-size: 17px;
        line-height: normal;
        padding-top: 10px;
    }
}


/* For 1190 Resolution */
@media (min-device-width: 1024px) and (max-device-width: 1199px) {

    /* .about-section .mobileview,
    .about-slider .mobileview {
        display: none;
    } */
    .about-section .about-slider .desktopview .slider-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 24px !important;
        line-height: 29px;
        /* or 104% */

        text-align: center;

        color: #122051;

    }

}

@media (min-device-width: 1024px) and (max-device-height: 768px) {

    /* .about-section .mobileview,
    .about-slider .mobileview {
        display: none;
    } */
    .about-section .about-slider .desktopview .slider-para {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-size: 24px !important;
        line-height: 29px;
        /* or 104% */

        text-align: center;

        color: #122051;

    }


}

@media (min-device-width: 1024px) and (max-device-width: 1080px) {
    .about-section .section-para {
        font-size: 24px !important;
        line-height: 36px;
    }

    .desktopview h3 {
        font-size: 82px !important;
        line-height: 100.3px;
    }

    .about-section img {
        height: 90%;
    }

    .who-we-are-sect .about-sub-heading {
        font-size: 61.2px;
        line-height: 76px;
    }
}

@media (min-device-width: 1920px) {
    .about-section .section-para {
        font-size: 31px !important;
        line-height: 45px;
    }

    .about-section .desktopview h3 {
        font-size: 99.7px !important;
        line-height: 121px;
    }

    .who-we-are-sect .about-sub-heading {
        font-size: 75.7px;
        line-height: 92px;
    }

    .who-we-are-sect .about-sub-para {
        font-size: 24px;
        line-height: 26px;
    }
}





@media (min-width: 1024px) and (max-width: 1280px) {

    .about-section .desktopview.about-container h3 {
        font-size: 76.8px;
        line-height: 94.4px;
    }

    .about-section .desktopview.about-container .section-para {
        font-size: 22.4px;
        line-height: 33.6px;
    }

    .about-section .desktopview.about-container h3 img {
        max-height: 35px;
    }

    .who-we-are-sect .about-sub-heading {
        font-size: 57.6px;
        line-height: 71.2px;
    }

    .about-section .about-sub-para.desktopview {
        font-size: 16.8px;
        line-height: 17.6px;
    }

    .about-section .about-list h4 {
        font-size: 16.8px;
        line-height: 20.8px;
    }

    .about-section .about-slider .slider-heading {
        font-size: 38.4px;
        font-size: 34px;
    }

    .about-section .about-slider .slider-para {
        font-size: 22.4px;
        line-height: 23.2px;
    }
}


@media (min-width: 1280px) and (max-width: 1360px) {

    .about-section .desktopview.about-container h3 {
        font-size: 81.6px;
        line-height: 100px;
    }

    .about-section .desktopview.about-container .section-para {
        font-size: 23.8px;
        line-height: 35.4px;
    }

    .about-section .desktopview.about-container h3 img {
        max-height: 37px;
    }

    .who-we-are-sect .about-sub-heading {
        font-size: 61.2px;
        line-height: 75.65px;
    }

    .about-section .about-sub-para.desktopview {
        font-size: 17.85px;
        line-height: 18.7px;
    }

    .about-section .about-list h4 {
        font-size: 17.85px;
        line-height: 22.1px;
    }

    .about-section .about-slider .slider-heading {
        font-size: 40.8px;
        font-size: 34px;
    }

    .about-section .about-slider .slider-para {
        font-size: 23.8px;
        line-height: 24.65px;
    }

}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {


    .about-section .desktopview.about-container .section-para {
        font-size: 25.2px;
        line-height: 37.8px;
        font-size: 23.8px;
        line-height: 35.4px;
    }

    
    .about-section .desktopview.about-container h3 {
        font-size: 86.4px;
        line-height: 106.2px;
    }

    .about-section .desktopview.about-container h3 img {
        max-height: 39px;
    }

    .who-we-are-sect .about-sub-heading {
        font-size: 64.8px;
        line-height: 80.1px;
    }

    .about-section .about-sub-para.desktopview {
        font-size: 18.9px;
        line-height: 19.8px;
    }

    .about-section .about-list h4 {
        font-size: 18.9px;
        line-height: 23.4px;
    }

    .about-section .about-slider .slider-heading {
        font-size: 43.2px;
        font-size: 34px;
    }

    .about-section .about-slider .slider-para {
        font-size: 25.1px;
        line-height: 26.1px;
    }

}



@media only screen and (min-width: 1600px) and (max-width: 1919px) {

    .about-section .desktopview.about-container h3 {
        font-size: 91.2px;
        line-height: 112.1px;
    }

    .about-section .desktopview.about-container .section-para {
        font-size: 26.6px;
        line-height: 39.9px;
    }

    .about-section .desktopview.about-container h3 img {
        max-height: 40px;
    }

    .who-we-are-sect .about-sub-heading {
        font-size: 68.4px;
        line-height: 84.55px;
    }

    .about-section .about-sub-para.desktopview {
        font-size: 19.95px;
        line-height: 20.9px;
    }

    .about-section .about-list h4 {
        font-size: 19.95px;
        line-height: 24.7px;
    }


    .about-section .about-slider .slider-heading {
        font-size: 45.6px;
    }

    .about-section .about-slider .slider-para {
        font-size: 26.6px;
        line-height: 27.55px;
    }

}

@media (min-width: 1024px) {
    .about-section .desktopview.about-container {
        padding: 20px 0;
    }
}