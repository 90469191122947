.advisory-section {
    margin-top: 130px;
    margin-bottom: 100px;
    padding-bottom: 100px;

}

.advisory-section .mt-para {
    margin-top: -15px;
}

.advisory-section .advsection-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    color: #122051;
}

.advisory-section .advsection-subheading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #BEBEBE;
}

.advisory-section .advsection-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    /* or 124% */
    color: #122051;
    padding-top: 30px;
    /* max-width: 850px; */

}

.advisory-section .advisorymobile-view {
    display: none;
}

.advisory-section .mobileview-footer {
    display: none;
}

/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 420px) {
    .advisory-section .advisorymobile-view {
        display: block;
    }

    .advisory-section .mobileview-footer {
        display: block;
    }

    .advisory-section .advisorydesktop-view {
        display: none;
    }

    .advisory-section .advisorymobile-view-margin {
        margin-top: -1px;
    }

    .advisory-section .advisorymobile-view .advsection-heading {
        font-weight: 600;
        font-size: 16px;
        line-height: 14px;
        /* identical to box height, or 88% */

        letter-spacing: 0.02em;

    }

    .advisory-section {
        margin-top: 10px;
    }

    .advisory-section .advisorymobile-view .advsection-subheading {
        font-weight: 700 !important;
        font-size: 8px !important;
        line-height: 129.7% !important;
        color: #122051 !important;
    }

    .advisory-section .advisorymobile-view .margin-div {
        margin-left: -80px;
        margin-top: 20px;
        padding: 0px 20px 0px 0px;

    }

    .advisorymobile-view img {
        /* margin:auto; */
        margin-right: 10px !important;
        padding-left: 20px;
        width: 100px;
    }

    .advisory-section {
        /* margin-top: 130px; */
        margin-bottom: -30px !important;
        padding-bottom: 0px;

    }

    .advisory-section .advisorymobile-view .advsection-para {
        font-weight: 300;
        font-size: 10px;
        line-height: 13px;
        margin: 0px 20px;
        color: #122051;
        /* text-align: justify; */
    }

    .mobileview-footer .footer-heading h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        /* or 69% */
        word-spacing: 0.3em;

        text-align: center;
        letter-spacing: 0.02em;

        color: #122051;
    }

    .mobileview-footer .footer-heading img {
        /* width: 30px; */
        height: 12px;
    }

    .mobileview-footer .contact-btn {

        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 20px 30px !important;
        color: #122051;

    }

    .advisory-section {
        margin-bottom: 60px;
        padding-bottom: 60px;

    }
}

/* For 480 Resolution */
@media only screen and (min-device-width : 420px) and (max-device-width : 767px) {
    .advisory-section .advisorymobile-view {
        display: block;
    }

    .advisory-section .mobileview-footer {
        display: block;
    }

    .advisory-section .advisorydesktop-view {
        display: none;
    }

    .advisory-section .advisorymobile-view-margin {
        margin-top: -60px;
    }

    .advisory-section .advisorymobile-view .advsection-heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 14px;
        /* identical to box height, or 88% */

        letter-spacing: 0.02em;

    }

    .advisory-section {
        margin-top: 45px;
    }

    .advisory-section .advisorymobile-view .advsection-subheading {
        font-weight: 700 !important;
        font-size: 10px !important;
        line-height: 129.7% !important;
        color: #122051 !important;
    }

    .advisory-section .advisorymobile-view .margin-div {
        margin-left: -160px;
        margin-top: 28px;
        padding: 0px 20px 0px 0px;

    }

    .advisory-section {
        /* margin-top: 130px; */
        margin-bottom: -30px !important;
        padding-bottom: 0px;

    }

    .advisory-section .advisorymobile-view .advsection-para {
        font-weight: 300;
        font-size: 12px;
        line-height: 13px;
        margin: 0px 20px;
        color: #122051;
        /* text-align: justify; */
    }

    .mobileview-footer .footer-heading h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        /* or 69% */
        word-spacing: 0.3em;

        text-align: center;
        letter-spacing: 0.02em;

        color: #122051;
    }

    .mobileview-footer .footer-heading img {
        /* width: 30px; */
        height: 12px;
    }

    .mobileview-footer .contact-btn {

        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 20px 30px !important;
        color: #122051;

    }

    .advisory-section {
        margin-bottom: 60px;
        padding-bottom: 60px;

    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

    .advisory-section {
        margin-top: 80px;
        margin-bottom: 50px;
        padding-bottom: 50px;
    }

    .advisory-section .mobileview-footer {
        display: block;
    }
    
    .advisory-section .advisorydesktop-view img {
        width: 100%;
    }

    .advisory-section .advsection-heading {    
        margin-top: 25px !important;
        font-size: 16px;
        line-height: normal;
    }

    .advisory-section .advsection-subheading {
        font-size: 12px;
        line-height: normal;
    }

    .advisory-section .advsection-para {
        font-size: 16px;
        line-height: normal;
    }
    
    /* .advisory-section .advisorymobile-view {
        display: block;
    }

    .mobileview-footer {
        display: block;
    }

    .advisory-section .advisorydesktop-view {
        display: none;
    }

    .advisory-section .advisorymobile-view-margin {
        margin-top: -60px;
    }

    .advisory-section .advisorymobile-view .advsection-heading {
        font-weight: 600;
        font-size: 18px;
        line-height: 14px;
        letter-spacing: 0.02em;
    }

    .advisory-section {
        margin-top: 45px;
    }

    .advisory-section .advisorymobile-view .advsection-subheading {
        font-weight: 700 !important;
        font-size: 10px !important;
        line-height: 129.7% !important;
        color: #122051 !important;
    }

    .advisory-section .advisorymobile-view .margin-div {
        margin-left: -160px;
        margin-top: 28px;
        padding: 0px 20px 0px 0px;

    }

    .advisory-section {
        margin-bottom: -30px !important;
        padding-bottom: 0px;
    }

    .advisory-section .advisorymobile-view .advsection-para {
        font-weight: 300;
        font-size: 12px;
        line-height: 13px;
        margin: 0px 20px;
        color: #122051;
    }

    .mobileview-footer .footer-heading h3 {
        font-family: 'Clash Display';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        word-spacing: 0.3em;
        text-align: center;
        letter-spacing: 0.02em;
        color: #122051;
    }

    .mobileview-footer .footer-heading img {
        height: 12px;
    }

    .mobileview-footer .contact-btn {
        font-weight: 600;
        font-size: 20px !important;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.02em;
        padding: 20px 30px !important;
        color: #122051;
    }

    .advisory-section {
        margin-bottom: 60px;
        padding-bottom: 60px;
    } */
}

@media  (min-device-width: 1024px)  and (max-device-width: 1360px) {
    .advisory-section .advsection-para {
        font-size: 18px;
        line-height: 22.5px;
        /* max-width: 780px; */
    }
    .advisory-section .advsection-heading {
        font-size: 22px;
        line-height: 27px;
    }
    
    .advisory-section .advsection-subheading {
        font-size: 13px;
        line-height: 16.15px;
    }

}
/* For 1990px Resolution */
@media (min-width: 1919px) {
    .advisory-section .advsection-para {

        font-size: 24px;
        line-height: 29px;

    }
}



/* For 1024 Resolution */
@media (min-width: 1024px) and (max-width: 1280px) {
    
    .advisory-council .advisory-heading {
        font-size: 76.8px;
        line-height: 94.4px;
    }

    .advisory-council .council-para {       
        font-size: 16.8px;
        line-height: 25.6px;
      
    }
    .advisory-section .advsection-para {
        max-width: 620px;
    }
}


@media (min-width: 1280px) and (max-width: 1360px) {

    .advisory-council .advisory-heading {
        font-size: 81.6px;
        line-height: 100.3px;
    }

    .advisory-council .council-para {       
        font-size: 17.85px;
        line-height: 27.2px;
    }
    .advisory-section .advsection-para {
        max-width: 630px;
    }
}


@media only screen and (min-width: 1360px) and (max-width: 1600px) {
    
    .advisory-council .advisory-heading {
        font-size: 86.4px;
        line-height: 106.2px;
    }

    .advisory-council .council-para {       
        font-size: 18.9px;
        line-height: 28.8px;
    }
    .advisory-section .advsection-para {
        max-width: 655px;
    }
}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {

    .advisory-council .advisory-heading {
        font-size: 91.2px;
        line-height: 112.1px;
    }

    .advisory-council .council-para {       
        font-size: 19.95px;
        line-height: 30.4px;
    }
    .advisory-section .advsection-para {
        max-width: 680px;
    }
}




/*  */
@media (min-width: 1024px) and (max-width: 1280px) {
  
    section.advisory-section img {
        max-width: 200px;
    }
    .exclusivebenefit h3.heading {
      font-size: 38.4px;
      line-height: 47.2px;   
    }  
  
    .advisory-section .advsection-heading {
        font-size: 20.8px;
    }

   .advisory-section .advsection-para {  
      font-size: 16.8px;
      line-height: 20.8px;
      
    }
  }
  
  
  @media only screen and (min-width: 1280px) and (max-width: 1360px) {
    .exclusivebenefit h3.heading {
      font-size: 40.8px;
      line-height: 50.15px;   
    }  
  
    .advisory-section .advsection-heading {
        font-size: 22.1px;
    }
    .advisory-section .advsection-para {  
     
      font-size: 17.85px;
      line-height: 22.1px;
    }
  }
  
  @media only screen and (min-width: 1360px) and (max-width: 1600px) {
    .exclusivebenefit h3.heading {
      font-size: 43.2px;
      line-height: 53.1px;   
    }  
  
    .advisory-section .advsection-heading {
        font-size: 23.4px;
    }
    .advisory-section .advsection-para {  
     
      font-size: 18.9px;
      line-height: 23.4px;
    }
  }
  
  
  @media only screen and (min-width: 1600px) and (max-width: 1919px) {
    .exclusivebenefit h3.heading {
      font-size: 45.6px;
      line-height: 56.05px;   
    }  
  
    .advisory-section .advsection-heading {
        font-size: 24.7px;
    }
    .advisory-section .advsection-para {  
     
      font-size: 19.95px;
      line-height: 24.7px;
    }
  }
  @media only screen and (min-width: 1920px) {
    
    .advisory-section .advsection-para {
        max-width: 840px;
    }
}