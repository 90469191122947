.tandc-section .tandc-section-para {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #122051;
    padding: 0px 50px 30px;
    /* margin-left: 60px;
    margin-right: 20px; */
    margin: auto auto;
}

.tandc-section .tandc-section-para h4,
.tandc-section .tandc-section-para p,
.tandc-section .tandc-section-para h3,
.tandc-section .tandc-section-para ul {
  margin: 30px 0px;
}

.tandc-section .tandc-section-para li {
  margin: 15px 0px;
}

/* For 480 Resolution */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

 .tandc-section {
   padding-top: 0rem !important;
   /* margin-top: 0px !important; */
 }


 .tandc-section .tandc-section-para {
   font-size: 12px;
   line-height: 13.2px;
   padding: 0px;
   width: 80%;
   margin: auto;
 }

}



/* For 640 Resolution */
@media only screen and (min-device-width : 480px) and (max-device-width : 767px) {

 .tandc-section .tandc-section-para {
   font-size: 14px;
   line-height: 13.2px;
   line-height: normal;
   padding: 0px;
   width: 90%;
   margin: auto;
 }
}


/* For 640 Resolution */
@media only screen and (min-device-width : 768px) and (max-device-width : 1023px) {

  .tandc-section .tandc-section-para {
    font-size: 16px;
    line-height: 13.2px;
    line-height: normal;
    padding: 0px;
    width: 90%;
    margin: auto;
  }
 }
 






/* For 1024 Resolution */
 @media (min-width: 1024px) and (max-width: 1280px) {
   
   /* .benefit-blog-section .blogheading {
     font-size: 38.4px;
     line-height: 47.2px;   
   }  

   .benefit-blog-section .viewbtn {
       font-size: 16.8px;
       line-height: 20.8px;  
   }
  */
   .blog-content h2 {
       font-size: 16.8px;
   }

   .tablinks h3.tab-heading {
     font-size: 16.8px;
     line-height: 20.8px;
   }

   .blog-content p {
       font-size: 12.8px;
       line-height: 15.2px;
   }
 }
 
 
 @media only screen and (min-width: 1280px) and (max-width: 1360px) {

   /* .benefit-blog-section .blogheading {
       font-size: 40.8px;
     line-height: 50.15px;   
   }  
 
   .benefit-blog-section .viewbtn {
    
     font-size: 17.85px;
     line-height: 22.1px;
   } */

   .blog-content h2 {
       font-size: 17.85px;
   }
   .blog-content p {
       font-size: 13.6px;
       line-height: 15.12px;
   }

}
 
 @media only screen and (min-width: 1360px) and (max-width: 1600px) {

   /* .benefit-blog-section .blogheading {
       font-size: 43.2px;
     line-height: 53.1px;   
   }  
 
   .benefit-blog-section .viewbtn {
    
     font-size: 18.9px;
     line-height: 23.4px;
   } */

   .blog-content h2 {
       font-size: 18.9px;
   }

   .blog-content p {
       font-size: 14.4px;
       line-height: 17.1px;
   }
 }
 
 
 @media only screen and (min-width: 1600px) and (max-width: 1919px) {
   /* .benefit-blog-section .blogheading {
       font-size: 45.6px;
     line-height: 56.05px;   
   }  
 
   .benefit-blog-section .viewbtn {
    
     font-size: 19.95px;
     line-height: 24.7px;
   } */
   .blog-content h2 {
       font-size: 19.95px;
   }
   .blog-content p {
       font-size: 15.2px;
       line-height: 18.05px;
   }
 }
 

 @media only screen and (min-width: 1920px) {
   /* .benefit-blog-section .blogheading {
       font-size: 52px;
     line-height: 62px;
    
   }  

   .benefit-blog-section .viewbtn {
    
     font-size: 24px;
     line-height: 29px;      
   } */
   
   .blog-content h2 {
       font-size: 24px;
   }
   .blog-content p {
       font-size: 18px;
       line-height: 21px;
   }
 }
 
 