 /*
.hovertab-section  .left-side.col {
   padding: 0% 0 0 5%;
     display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
 */
 .hovertab-section h3.heading {
  /* padding: 0% 0 0 5%; */
  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  letter-spacing: 0.04em;
  color: #122051;
}

/* For 640 Resolution */
/* @media only screen and (min-device-width : 481px) and (max-device-width : 640px)  {

 .hovertab-section .mobile-view-img .tabimg img{
 width: 90% !important;
} */

/* .hovertab-section .mobile-view-img .tabimg img{
 width: 90% !important;
}  */



.tablinks {
  cursor: pointer;
}

.tablinks h3.tab-heading {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  color: #BEBEBE;
}


.tablinks.active h3.tab-heading {
  color: #122051;
}

/* Style the tab */
.tab.hover-tabs {
  border: none;
  width: 100%;
  height: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}


.tabimg img {
  align-items: center;
  width: 75px;
  height: auto;
}

.tab-text {
  border-bottom: 1px solid #BEBEBE;
  /* margin: auto; */
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  padding: 100px;
  padding: 100px 0px 100px;
  width: 100%;
  height: 100%;
  text-align: right;
  border-left: none;
  display: none;
  transition: all 2s ease-in .5s;
}

.desktop.ex-memberbenefits .tabcontent {
  padding: 50px 100px 100px 100px;
}

.ex-memberbenefits .applynow-btn {
  transition: all 0.5s;
}


.ex-memberbenefits .applynow-btn:hover {
  background: #122051;    
  color:#B2E219;
}

.desktop.ex-memberbenefits .tabcontent img {
  max-width: 527px;
}

.desktop.ex-memberbenefits .text-center {
  margin-top:50px;
}

.tabcontent img {
  width: 100%;
  /* height: 100%; */
  border-radius: 25px;
}

.tabcontent.active {
  transition: display 2s ease-in-out;
  display: block;
}


.content-box .tabcontent {
  transition: display 2s ease-in-out;
  transition-delay: 1s
}



/* member benefits */

.hovertab-section .ex-memberbenefits.container-fluid {
  padding-top: 75px;
  padding-bottom: 50px;
}


.hovertab-section .mobile {
  padding-top: 30px;
  padding-bottom: 30px;
}

.mobile .memberbenefits h3.heading {

  font-family: 'Clash Display';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #122051;
}

/* For 480 Resolution */
/* @media only screen and (min-device-width : 320px) and (max-device-width : 640px) {
  .memberbenefits .mobileviewhover .imgheading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    padding-top: 20px;
    text-transform: uppercase;
    color: #122051;
  
  }

  .memberbenefits .mobileviewhover {
    width: 100%;
  }

  .memberbenefits .d-flex {
     flex-direction: column;
     justify-content: center;
   }
  .memberbenefits .mobileviewhover img {
    width: 100%;
    max-width: 640px;
    margin: auto;
    padding-left: 10px;
    padding-right: 25px;
    border-radius: 35px 45px 45px 35px;
    height: 100%;
  }

  .memberbenefits h3.heading {

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #122051;
  }

  .mobileviewhover .borderbottom {
    border-bottom: 2px solid #BEBEBE;
  }

  .ex-memberbenefits .mobile-view-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px !important;
    line-height: 28px !important;
    text-align: center;
    letter-spacing: 0.02em !important;
    color: #BEBEBE !important;
    margin-top: -30px;
  }
  .ex-memberbenefits .tab-heading{
   font-family: 'Gotham';
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;
   text-align: center;

   color: #122051;
  }
  .ex-memberbenefits .tab-text{
     border-bottom: 0px;
  }
  .ex-memberbenefits button{
     margin-top: 20px !important;
  }
  .ex-memberbenefits .applynow-btn{
   padding: 10px 40px !important;
   font-size: 20px !important;
  }
} */

@media only screen and (min-device-width : 320px) and (max-device-width : 1023px) {

  .memberbenefits .d-flex {
     flex-direction: column;
     justify-content: center;
   }

  .memberbenefits .mobileviewhover img {
    width: 100% !important;
    max-width: 650px;
    margin: auto;
    padding-left: 0px;
    /* padding-right: 25px; */
    border-radius: 15px;
    height: 100%;
  }
  /* .memberbenefits .mobileviewhover .imgdiv{
    border-radius: 35px;

  } */
  
}



 /* For 420 Resolution */
 @media only screen and (min-device-width : 320px) and (max-device-width : 420px) {
  .memberbenefits .mobileviewhover .imgheading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    padding-top: 20px;
    text-transform: uppercase;
    /* text-align:justify; */
    color: #122051;
    transform: translateX(10px);
    /* or 79% */


  }

  .memberbenefits .mobileviewhover {
    width: 100%;
  }


  .memberbenefits h3.heading {

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #122051;
  }

  .mobileviewhover .borderbottom {
    border-bottom: 2px solid #BEBEBE;
    ;
  }

  .ex-memberbenefits .mobile-view-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 28px !important;
    /* or 115% */

    text-align: center;
    letter-spacing: 0.02em !important;

    color: #BEBEBE !important;
    margin-top: -30px;

  }

  .ex-memberbenefits .tab-heading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #122051;
  }

  .ex-memberbenefits .tab-text {
    border-bottom: 0px;
  }

  .ex-memberbenefits button {
    margin-top: 20px !important;
  }

  .ex-memberbenefits .applynow-btn {
    padding: 10px 40px !important;
    font-size: 20px !important;
  }
}

/* For 420 Resolution */
@media only screen and (min-device-width : 420px) and (max-device-width : 767px) {
  .memberbenefits .mobileviewhover .imgheading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 11px;
    padding-top: 20px;
    text-transform: uppercase;
    transform: translateX(10px);

    /* text-align:justify; */
    color: #122051;
    /* or 79% */


  }

  .memberbenefits .mobileviewhover {
    width: 100%;
  }


  .memberbenefits h3.heading {

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #122051;
  }

  .mobileviewhover .borderbottom {
    border-bottom: 2px solid #BEBEBE;
    ;
  }

  .ex-memberbenefits .mobile-view-heading {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 24px !important;
    line-height: 28px !important;
    /* or 115% */

    text-align: center;
    letter-spacing: 0.02em !important;

    color: #BEBEBE !important;
    margin-top: -30px;

  }

  .ex-memberbenefits .tab-heading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #122051;
  }

  .ex-memberbenefits .tab-text {
    border-bottom: 0px;
  }

  .ex-memberbenefits button {
    margin-top: 20px !important;
  }

  .ex-memberbenefits .applynow-btn {
    padding: 10px 40px !important;
    font-size: 20px !important;
  }
}

.middle {
  align-items: center;
}


@media (min-width: 768px) and (max-width: 1023px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .memberbenefits.mobile .d-flex {
    max-width: 650px;
    margin: auto;
    box-sizing: unset;
  }

  
  .memberbenefits .mobileviewhover .imgheading {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 11px;
    padding-top: 20px;
    text-transform: uppercase;
    transform: translateX(10px);

    /* text-align:justify; */
    color: #122051;
    /* or 79% */
  }

  .memberbenefits .mobileviewhover {
    width: 100%;
  }

  .mobileviewhover .borderbottom {
    border-bottom: 2px solid #BEBEBE;
    ;
  }

  .memberbenefits h3.heading {

    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: normal;
    letter-spacing: 0.04em;
    color: #122051;
  }

  /*  .ex-memberbenefits */
  .hovertab-section .ex-memberbenefits.container-fluid {
    padding-top: 75px;
    padding-bottom: 50px;
  }

  .hovertab-section .ex-memberbenefits h3.heading {
    font-size: 35px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.02em !important;
    color: #BEBEBE !important;
  }

  .hovertab-section .ex-memberbenefits .tab.hover-tabs {
    max-width: unset;
  }

  .hovertab-section .ex-memberbenefits .tab.hover-tabs .tab-text  {
    border: none;
    max-width: 700px;
    margin: auto;
  }

  .ex-memberbenefits .tab-heading {
    font-family: 'Gotham';
    font-size: 22.4px;
    line-height: 33.6px;
    color: #122051;
    font-style: normal;
    font-weight: 700;
  }
}


/* For 1024 Resolution */

@media (min-width: 1024px) and (max-width: 1440px) {
    
  .tabcontent {
    padding: 20px;
    vertical-align: middle;
  }
}
@media (min-width: 1441px) and (max-width: 1680px) {
    
  .tabcontent {
    padding: 50px;
  }
}


@media (min-width: 1024px) and (max-width: 1280px) {
  
  .hovertab-section h3.heading {
    font-size: 38.4px;
    line-height: 47.2px;   
  }  

  .tablinks h3.tab-heading {
   
    font-size: 16.8px;
    line-height: 20.8px;
    
  }
}


@media only screen and (min-width: 1280px) and (max-width: 1360px) {
  .hovertab-section h3.heading {
    font-size: 40.8px;
    line-height: 50.15px;   
  }  

  .tablinks h3.tab-heading {
   
    font-size: 17.85px;
    line-height: 22.1px;
  }
}

@media only screen and (min-width: 1360px) and (max-width: 1600px) {
  .hovertab-section h3.heading {
    font-size: 43.2px;
    line-height: 53.1px;   
  }  

  .tablinks h3.tab-heading {
   
    font-size: 18.9px;
    line-height: 23.4px;
  }
}


@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .hovertab-section h3.heading {
    font-size: 45.6px;
    line-height: 56.05px;   
  }  

  .tablinks h3.tab-heading {
   
    font-size: 19.95px;
    line-height: 24.7px;
  }
}

@media only screen and (min-width: 1920px) {
  .hovertab-section h3.heading {
    font-size: 52px;
    line-height: 62px;
   
  }  
  .tablinks h3.tab-heading {
   
    font-size: 24px;
    line-height: 29px;
    
  }
}

